// Login.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import LoginForm from '../components/login/LoginForm';
import ForgotPasswordForm from '../components/login/ForgotPasswordForm';
import useDocumentTitle from '../hooks/use-document-title';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  useDocumentTitle('Logowanie');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, []);

  return (
    <section className="login section fill-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
            <div className="form-head">
              {showForgotPassword ? (
                <ForgotPasswordForm setShowForgotPassword={setShowForgotPassword} />
              ) : (
                <LoginForm setShowForgotPassword={setShowForgotPassword} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
