import { create } from 'zustand';
import {
  FetchUserBooksResponse,
  FetchUserDetailsResponse,
  FetchUserPurchasesResponse,
  UserBook,
  fetchUserBooks,
  fetchUserDetails,
  fetchUserLookups,
  fetchUserPurchases,
  getIsBookPurchased,
  saveUserDetails,
} from '../services/userApi';
import { Axios } from 'axios';

interface UserState {
  user: any;
  userLookups: number;
  fetchUserLookupsAction: () => Promise<void>;
  userBooksResults: FetchUserBooksResponse | null;
  booksLoading: boolean;
  fetchUserBooks: (page: number) => Promise<void>;
  booksError: string | null;
  fetchUserPurchases: () => Promise<void>;
  userPurchasesError: string | null;
  userPurchasesLoading: boolean;
  userPurchases: FetchUserPurchasesResponse | null;
  clear: () => void;
  fetchUserDetails: () => Promise<void>;
  saveUserDetails: (user: any) => Promise<void>;
  saveUserDetailsError: string | null;
  userDetails: FetchUserDetailsResponse;
  getIsBookPurchased: (bookId: number) => Promise<boolean>;
}

export const useUserStore = create<UserState>((set) => ({
  user: null,
  userLookups: 0,
  booksLoading: false,
  booksError: null,
  userPurchasesError: null,
  userPurchasesLoading: false,
  userPurchases: null,
  userBooksResults: {
    data: [],
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
  },
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  fetchUserLookupsAction: async () => {
    try {
      const lookups = await fetchUserLookups();
      set({ userLookups: lookups });
    } catch (error) {
      console.error('Failed to fetch user lookups', error);
    }
  },
  fetchUserBooks: async (page) => {
    try {
      set({ booksLoading: true });
      const response = await fetchUserBooks(page);
      set({ userBooksResults: response });
      set({ booksLoading: false });
    } catch (error) {
      set({ booksError: 'Failed to fetch user books' });
      console.error('Failed to fetch user books', error);
      set({ booksLoading: false });
    }
  },
  fetchUserPurchases: async (): Promise<void> => {
    set({ userPurchasesLoading: true });
    set({ userPurchasesError: null });
    set({ userPurchases: null });
    try {
      const purchases = await fetchUserPurchases();
      set({ userPurchases: purchases });
      set({ userPurchasesLoading: false });
    } catch (error) {
      set({ userPurchasesError: 'Failed to fetch user purchases' });
      set({ userPurchasesLoading: false });
      console.error('Failed to fetch user purchases', error);
    }
  },
  fetchUserDetails: async () => {
    try {
      const userDetails = await fetchUserDetails();
      set({ userDetails });
    } catch (error) {
      console.error('Failed to fetch user details', error);
    }
  },
  saveUserDetails: async (userDetails) => {
    set({ saveUserDetailsError: null });
    try {
      const response = await saveUserDetails(userDetails);
      set({ userDetails: response });
    } catch (error) {
      console.error('Failed to save user details', error);
      set({ saveUserDetailsError: 'Nie udało się zmienić danych. Spróbuj ponownie później' });
    }
  },
  saveUserDetailsError: null,
  clear: () => {
    set({
      user: null,
      userLookups: 0,
      booksLoading: false,
      booksError: null,
      userPurchasesError: null,
      userPurchasesLoading: false,
      userPurchases: null,
      userBooksResults: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalCount: 0,
      },
    });
  },
  getIsBookPurchased: async (bookId) => {
    try {
      const response = await getIsBookPurchased(bookId);
      return response.purchased;
    } catch (error) {
      console.error('Failed to check if book is purchased', error);
      return false;
    }
  },
}));
