import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavItemProps {
  to: string;
  iconClass: string;
  children: React.ReactNode;
}

const NavItem: React.FC<NavItemProps> = ({ to, iconClass, children }) => {
  // Include children in the destructuring
  return (
    <NavLink to={to}>
      <i className={`lni lni-${iconClass}`}></i>
      {children}
    </NavLink>
  );
};

export default NavItem;
