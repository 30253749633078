import React from 'react';
import CountUp from 'react-countup';

interface AchievementItemProps {
  count: number;
  title: string;
  delay: string;
  duration: number;
  thousands?: boolean;
}

import styles from './AchievmentsItem.module.scss';

const AchievementsItem: React.FC<AchievementItemProps> = ({ count, title, delay, duration, thousands = false }) => {
  return (
    <div className="col-lg-3 col-md-3 col-12">
      <div className={`${styles.singleAchievment} single-achievement wow fadeInUp`} data-wow-delay={delay}>
        <h3 className="counter">
          <CountUp end={count} duration={duration} />
          {thousands ? ' tys.' : ''}
        </h3>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default AchievementsItem;
