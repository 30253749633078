import React from 'react';
import { useUserStore } from '../../store/userStore';
import Pagination from '../search/Pagination';
import UserPurchaseListItem from './PurchaseListItem';

const Purchases: React.FC = () => {
  const { fetchUserPurchases, userPurchases } = useUserStore();

  React.useEffect(() => {
    fetchUserPurchases();
  }, []);

  return (
    <div className="main-content">
      <div className="dashboard-block mt-0">
        <h3 className="block-title">Moje zakupy</h3>
        <div className="my-items">
          <div className="item-list-title">
            <div className="row align-items-center">
              <div className="col-6 col-md-2">
                <p>Nazwa</p>
              </div>
              <div className="col-4 d-none d-md-block">
                <p>Koszt</p>
              </div>
              <div className="col-2 d-none d-md-block">
                <p>Data zakupu</p>
              </div>
              <div className="col-6 col-md-2">{/* <p>Faktura</p> */}</div>
              <div className="col-2" />
            </div>
          </div>
          {userPurchases && (
            <Pagination
              currentPage={userPurchases.currentPage}
              totalPages={userPurchases.totalPages}
              onPageChange={fetchUserPurchases}
            />
          )}
          {userPurchases?.data.map((purchase) => (
            <UserPurchaseListItem key={purchase.purchaseDate} purchase={purchase} />
          ))}
          {userPurchases && (
            <Pagination
              currentPage={userPurchases.currentPage}
              totalPages={userPurchases.totalPages}
              onPageChange={fetchUserPurchases}
            />
          )}
          {userPurchases?.data.length === 0 && (
            <div className="col-12 text-center mt-40 mb-20">
              <h5 className="empty-state-header">Brak zakupów do wyświetlenia</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Purchases;
