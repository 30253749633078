import React from 'react';
import QuickLinks from './QuickLinks';
import Contact from './Contact';
import BottomLinks from './BottomLinks';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-6 col-12">
              <QuickLinks />
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Contact />
            </div>
          </div>
        </div>
      </div> */}
      <div className="footer-bottom">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-12">
                <BottomLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
