import React from 'react';
import { Link } from 'react-router-dom';

const BottomLinks: React.FC = () => {
  return (
    <div className="content">
      <ul className="footer-bottom-links">
        <li>
          <Link to="/regulamin">Regulamin</Link>
        </li>
        <li>
          <Link to="/polityka_prywatnosci">Polityka prywatności</Link>
        </li>
        <li>
          <Link to="/kontakt">Kontakt</Link>
        </li>
      </ul>
    </div>
  );
};

export default BottomLinks;
