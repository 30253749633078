import React, { useEffect, useState } from 'react';

import './_assets/css/animate.css';
import './_assets/css/bootstrap.min.css';
import './_assets/css/LineIcons.2.0.css';

import './_assets/css/tiny-slider.css';
import './_assets/css/glightbox.min.css';

import './scss/main.scss';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import WOW from 'wowjs';
import 'animate.css';
import Home from './pages/Home';
import Login from './pages/Login';
import { Footer } from './components/footer/Footer';
import Register from './pages/Register';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import { CheckoutForm } from './components/checkout/CheckoutForm';
import { Return } from './components/checkout/Return';
import Header from './components/header/Header';
import ConsentModal from './components/consent-modal/ConsentModal';

import useAuthStore from './store/authStore';
import Order from './pages/Order';
import ThankYou from './pages/ThankYou';
import ScrollToTop from './components/ScrollToTop';
import SearchLot from './pages/SearchLot';
import SearchAddress from './pages/SearchAddress';
import RegisterSuccess from './pages/RegisterSuccess';
import Regulamin from './pages/Regulamin';
import Privacy from './pages/Privacy';
function App() {
  const validateToken = useAuthStore((state) => state.validateToken);

  useEffect(() => {
    function fadeout() {
      const preloader = document.querySelector('.preloader') as HTMLElement;
      if (preloader) {
        preloader.style.opacity = '0';
        preloader.style.display = 'none';
      }
    }
    window.onload = function () {
      window.setTimeout(fadeout, 200);
    };

    // Sticky Navbar
    window.onscroll = function () {
      const headerNavbar = document.querySelector('.navbar-area') as HTMLElement;
      const sticky = headerNavbar ? headerNavbar.offsetTop : 0;

      if (window.pageYOffset > sticky) {
        headerNavbar?.classList.add('sticky');
      } else {
        headerNavbar?.classList.remove('sticky');
      }

      // Show or hide the back-to-top button
      const backToTop = document.querySelector('.scroll-top') as HTMLElement;
      if (backToTop) {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          backToTop.style.display = 'flex';
        } else {
          backToTop.style.display = 'none';
        }
      }
    };

    // Mobile menu button
    const navbarToggler = document.querySelector('.mobile-menu-btn');
    if (navbarToggler) {
      navbarToggler.addEventListener('click', function () {
        navbarToggler.classList.toggle('active');
      });
    }

    const wow = new WOW.WOW();
    wow.init();
  }, []);

  useEffect(() => {
    validateToken();
  }, []);

  const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
      // track pageview with gtag / react-ga / react-ga4, for example:
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'page_view', {
          page_path: location.pathname + location.search,
        });
      }
    }, [location]);
  };
  return (
    <Router>
      <div className="App">
        <ConsentModal />
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/logowanie" element={<Login />} />
          <Route path="/rejestracja" element={<Register />} />
          <Route path="/rejestracja/sukces" element={<RegisterSuccess />} />
          <Route path="/ksiegi-wieczyste-po-adresie" element={<SearchAddress />} />
          <Route path="/ksiegi-wieczyste-po-nr-dzialki" element={<SearchLot />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route path="/return" element={<Return />} />
          <Route path="/moje-konto/*" element={<Dashboard />} />
          <Route path="/zamowienie" element={<Order />} />
          <Route path="/zamowienie/dziekujemy" element={<ThankYou />} />
          <Route path="/regulamin" element={<Regulamin />} />
          <Route path="/polityka_prywatnosci" element={<Privacy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
