import { create } from 'zustand';
import axios from '../services/axios';
import { useUserStore } from './userStore';

interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
  register: (token: string) => void;
  validateToken: () => Promise<boolean>;
  loginAdditionalInfo: string;
  setLoginAdditionalInfo: (info: string) => void;
  customRedirect: string;
  setCustomRedirect: (redirect: string) => void;
  registrationSuccess: boolean;
  setRegistrationSuccess: (success: boolean) => void;
  changePassword: (currentPassword: string, newPassword: string) => Promise<void>;
}

const useAuthStore = create<AuthState>((set) => ({
  isLoggedIn: !!localStorage.getItem('token') || !!sessionStorage.getItem('token'),
  token: null,
  loginAdditionalInfo: '',
  customRedirect: '',
  setCustomRedirect: (redirect) => {
    set({ customRedirect: redirect });
  },
  setLoginAdditionalInfo: (info) => {
    set({ loginAdditionalInfo: info });
  },
  login: (token: string) => {
    set({ isLoggedIn: true, token });
  },
  logout: () => {
    set({ isLoggedIn: false, token: null });
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    // Call the clear action from the user store
    useUserStore.getState().clear();
  },
  register: (token: string) => {
    set({ isLoggedIn: true, token });
  },
  validateToken: async () => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get('/auth/validate-token', {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.valid) {
          set({ isLoggedIn: true, token });
          return true;
        }
      } catch (error) {
        set({ isLoggedIn: false, token: null });
        console.error('Token validation failed', error);
      }
    }
    set({ isLoggedIn: false, token: null });
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    return false;
  },
  registrationSuccess: false,
  setRegistrationSuccess: (success) => {
    set({ registrationSuccess: success });
  },
  changePassword: async (currentPassword, newPassword) => {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
    if (token) {
      try {
        await axios.post(
          '/auth/change-password',
          { currentPassword, newPassword },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error('No token available');
    }
  },
}));

export const selectIsLoggedIn = (state: AuthState) => state.isLoggedIn;
export default useAuthStore;
