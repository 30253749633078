import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import useDocumentTitle from '../hooks/use-document-title';

const Regulamin: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  useDocumentTitle('Polityka Prywatności');

  return (
    <section className="login registration section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-12">
            <div className="form-head privacy">
              <h4 className="title">Polityka prywatności</h4>
              <h4>§ 1 Postanowienia ogólne</h4>
              <ol>
                <li>
                  Administratorem danych osobowych jest spółka Data Mansion LLC z siedzibą w Delaware 651 N Broad
                  Street, Suite 201, Middletown, Delaware 19709. Kontakt pod adresem kontakt@ksiegiwieczyste.io.
                </li>
                <li>Na podstawie Art. 37 RODO, spółka „DataMansion LLC.” nie powołała Inspektora Ochrony Danych.</li>
                <li>
                  Polityka prywatności stanowi integralną część Regulaminu. Korzystając z oferowanych przez nas usług,
                  powierzasz nam swoje informacje. Niniejszy dokument służy jedynie jako pomoc w zrozumieniu, jakie
                  informacje i dane są zbierane i w jakim celu oraz do czego są wykorzystywane. Te dane są bardzo dla
                  nas ważne, dlatego prosimy o dokładne zapoznanie się z tym dokumentem gdyż określa on zasady oraz
                  sposoby przetwarzania i ochrony danych osobowych. Dokument ten określa także zasady stosowania plików
                  „Cookies”.
                </li>
                <li>
                  Informujemy, że przestrzegamy zasad ochrony danych osobowych oraz wszelkich uregulowań prawnych, które
                  są przewidziane Ustawą o ochronie danych osobowych oraz Rozporządzeniem Parlamentu Europejskiego i
                  Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
                  przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia
                  dyrektywy 95/46/WE.
                </li>
                <li>
                  Na żądanie osoby, której dane osobowe są przetwarzane udzielamy wyczerpujących informacji w jaki
                  sposób wykorzystujemy jego dane osobowe. Zawsze w jasny sposób staramy się poinformować o danych,
                  które gromadzimy, w jaki sposób je wykorzystujemy, jakim celom mają służyć i komu je przekazujemy,
                  jaką zapewniamy ochronę tych danych przy przekazaniu innym podmiotom oraz udzielamy informacji o
                  instytucjach, z którymi należy się skontaktować w razie wątpliwości.
                </li>
              </ol>
              <h4>§ 2 Zasady prywatności</h4>
              <ol>
                <li>Szanujemy Twoją prywatność. Pragniemy zagwarantować Ci wygodę korzystania z naszych usług.</li>
                <li>
                  Cenimy zaufanie, jakim Nas obdarzasz, powierzając nam swoje dane osobowe w celu realizacji usług.
                  Zawsze korzystamy z danych osobowych w sposób uczciwy oraz tak, aby nie zawieść Twojego zaufania,
                  tylko w zakresie niezbędnym do realizacji usług.
                </li>
                <li>
                  Jako Użytkownik masz prawo do uzyskania pełnych i jasnych informacji o tym, w jaki sposób
                  wykorzystujemy Twoje dane osobowe i do jakich celów są niezbędne. Zawsze w klarowny sposób informujemy
                  o danych, które zbieramy, w jaki sposób i komu je udostępniamy oraz udzielamy informacji o podmiotach,
                  z którymi należy się skontaktować w razie wątpliwości.
                </li>
                <li>
                  W razie wątpliwości odnośnie wykorzystywania przez nas Twoich danych osobowych, niezwłocznie
                  podejmiemy działania w celu wyjaśnienia i rozwiania takich wątpliwości. W sposób wyczerpujący
                  odpowiadamy na wszystkie pytania z tym związane.
                </li>
                <li>
                  Podejmiemy wszystkie uzasadnione działania, aby chronić Twoje dane przed nienależytym i
                  niekontrolowanym wykorzystaniem.
                </li>
                <li>
                  Podstawą prawną przetwarzania Twoich danych osobowych jest:
                  <ol className="alpha">
                    <li>
                      art. 6 ust. 1 lit. a osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych
                      osobowych w jednym lub większej liczbieokreślonych celów
                    </li>
                    <li>
                      art. 6 ust. 1 lit. b przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba,
                      której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed
                      zawarciem umowy
                    </li>
                    <li>
                      art. 6 ust. 1 lit. c przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na
                      administratorze
                    </li>
                    <li>
                      art. 6 ust. 1 lit. d przetwarzanie jest niezbędne do ochrony żywotnych interesów osoby, której
                      dane dotyczą, lub innej osoby fizycznej
                    </li>
                    <li>
                      art. 6 ust. 1 lit. e przetwarzanie jest niezbędne do wykonania zadania realizowanego w interesie
                      publicznym lub w ramach sprawowania władzy publicznej powierzonej administratorowi
                    </li>
                    <li>
                      art. 6 ust. 1 lit. f przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych
                      interesów realizowanych przez administratora lub przez stronę trzecią
                    </li>
                  </ol>
                </li>
                <li>
                  Twoje dane osobowe związane z zawarciem i realizacją umowy przetwarzane będą przez okres jej
                  realizacji, a także przez okres nie dłuższy niż przewidują to przepisy prawa, w tym przepisy Kodeksu
                  cywilnego oraz ustawy o rachunkowości, tj. nie dłużej niż przez 10 lat, licząc od końca roku
                  kalendarzowego w którym ostatnia umowa została wykonana.
                </li>
                <li>
                  Twoje dane osobowe przetwarzane w celu zawarcia i wykonania przyszłych umów będą przetwarzane do czasu
                  zgłoszenia sprzeciwu.
                </li>
                <li>
                  Przysługuje Ci prawo do: dostępu do swoich danych osobowych i otrzymania kopii danych osobowych
                  podlegających przetwarzaniu, sprostowania swoich nieprawidłowych danych; żądania usunięcia danych
                  (prawo do bycia zapomnianym) w przypadku wystąpienia okoliczności przewidzianych w art. 17 RODO;
                  żądania ograniczenia przetwarzania danych w przypadkach wskazanych w art. 18 RODO, wniesienia
                  sprzeciwu wobec przetwarzania danych w przypadkach wskazanych w art. 21 RODO, przenoszenia
                  dostarczonych danych, przetwarzanych w sposób zautomatyzowany.
                </li>
                <li>
                  Jeżeli uważasz, że Twoje dane osobowe są przetwarzane niezgodnie z prawem, możecie wnieść skargę do
                  organu nadzorczego (Urząd Ochrony Danych Osobowych, ul. Stawki 2, Warszawa). Jeśli potrzebujesz
                  dodatkowych informacji związanych z ochroną danych osobowych lub chcesz skorzystać z przysługujących
                  praw, skontaktuj się z nami listownie na adres korespondencyjny.
                </li>
                <li>
                  Przestrzegamy wszystkich obowiązujących przepisów i regulacji dotyczących ochrony danych i będziemy
                  współpracować z organami zajmującymi się ochroną danych oraz uprawnionymi do tego organami ścigania. W
                  przypadku braku przepisów dotyczących ochrony danych, będziemy postępować zgodnie z ogólnie przyjętymi
                  zasadami ochrony danych, zasadami współżycia społecznego jak i ustalonymi zwyczajami.
                </li>
                <li>
                  W razie pytań, zapraszamy do kontaktu za pomocą strony, z której zostałeś przekierowany do niniejszej
                  Polityki prywatności. Prośba o kontakt zostanie niezwłocznie przekazana do odpowiedniej powołanej do
                  tego osoby.
                </li>
                <li>
                  Aby ułatwić nam odpowiedź bądź ustosunkowanie się do podanych informacji, prosimy o podanie imienia i
                  nazwiska.
                </li>
              </ol>
              <h4>3 Zakres i cel zbierania danych osobowych</h4>
              <ol>
                <li>
                  Przetwarzamy niezbędne dane osobowe w celu realizacji usług oraz w celach księgowych i tylko takich.
                </li>
                <li>
                  Zbieramy, przetwarzamy i przechowujemy następujące dane użytkowników:
                  <ol className="alpha">
                    <li>imię i nazwisko,</li>
                    <li>adres zamieszkania,</li>
                    <li>adres do doręczeń (jeśli jest inny niż adres zamieszkania),</li>
                    <li>numer identyfikacji podatkowej (NIP),</li>
                    <li>adres poczty elektronicznej (e-mail),</li>
                    <li>numer telefonu (komórkowy, stacjonarny),</li>
                    <li>informacje o używanej przeglądarce internetowej,</li>
                    <li>inne dobrowolnie przekazane nam dane osobowe.</li>
                  </ol>
                </li>
                <li>
                  Podanie powyższych danych przez jest całkowicie dobrowolne ale także i niezbędne do pełnej realizacji
                  usług.
                </li>
                <li>
                  Możemy przesyłać dane osobowe do serwerów znajdujących się poza krajem Twojego zamieszkania lub do
                  podmiotów powiązanych, stron trzecich z siedzibą w innych krajach w tym krajach z obszaru EOG
                  (Europejski Obszar Gospodarczy, EOG ang. European Economic Area, EEA – strefa wolnego handlu i Wspólny
                  Rynek, obejmujące państwa Unii Europejskiej i Europejskiego Stowarzyszenia Wolnego Handlu EFTA) w celu
                  przetwarzania danych osobowych przez takie podmioty w naszym imieniu zgodnie z postanowieniami
                  niniejszej Polityki prywatności oraz obowiązującymi przepisami prawa, zwyczajami jak i regulacjami
                  dotyczącymi ochrony danych.
                </li>
                <li>
                  Dostęp do Twoich danych mogą posiadać podmioty świadczące dla usługi niezbędne do prowadzenia serwisu
                  tj.:
                  <ol className="alpha">
                    <li>Firmy hostingowe, świadczące usługi hostingu lub usług pokrewnych dla Administratora</li>
                    <li>Firmy, za pośrednictwem których świadczona jest usługa Newslettera</li>
                    <li>
                      Firmy serwisowe i wsparcia IT dokonujące konserwacji lub odpowiedzialne za utrzymanie
                      infrastruktury IT
                    </li>
                    <li>
                      Firmy pośredniczące w płatnościach on-line za towaru lub usługi oferowane w ramach Serwisu (w
                      przypadku dokonywania transakcji zakupu w Serwisie)
                    </li>
                    <li>
                      Firmy pośredniczące w płatnościach mobilnych za towaru lub usługi oferowane w ramach Serwisu (w
                      przypadku dokonywania transakcji zakupu w Serwisie)
                    </li>
                    <li>
                      Firmy odpowiedzialne za prowadzenie księgowości Administratora (w przypadku dokonywania transakcji
                      zakupu w Serwisie)
                    </li>
                    <li>
                      Firmy odpowiedzialne za dostarczanie produktów fizycznych do Użytkownika (usługi pocztowe /
                      kurierskie w przypadku dokonywania transakcji zakupu w Serwisie)
                    </li>
                  </ol>
                </li>
              </ol>
              <h4>§ 4 Pliki Cookie</h4>
              <ol>
                <li>
                  Stosuje pliki cookie lub podobne technologie (zwane dalej łącznie: „plikami cookie”) poprzez które
                  należy rozumieć dane informatyczne, w szczególności pliki tekstowe, przeznaczone do korzystania ze
                  strony internetowej i przechowywane w urządzeniach końcowych Użytkowników przeglądających strony.
                  Informacje zbierane przy pomocy plików cookie pozwalają dostosowywać usługi i treści do indywidualnych
                  potrzeb, a także preferencji użytkowników, jak również służą do opracowywania ogólnych statystyk
                  dotyczących korzystania przez użytkowników ze stron. Dane gromadzone przy użyciu plików cookie są
                  zbierane wyłącznie w celu wykonywania określonych funkcji na rzecz Użytkowników i są zaszyfrowane w
                  sposób uniemożliwiający dostęp do nich osobom nieuprawnionym.
                </li>
                <li>
                  Na naszej stronie stosujemy pliki cookie:
                  <ol className="alpha">
                    <li>
                      <b>Cookies wewnętrzne</b> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez system
                      teleinformatyczny Serwisu
                    </li>
                    <li>
                      <b>Cookies zewnętrzne</b> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez
                      systemy teleinformatyczne Serwisów zewnętrznych. Skrypty Serwisów zewnętrznych, które mogą
                      umieszczać pliki Cookies na Urządzeniach Użytkownika zostały świadomie umieszczone w Serwisie
                      poprzez skrypty i usługi udostępnione i zainstalowane w Serwisie
                    </li>
                    <li>
                      <b>Cookies sesyjne</b> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis
                      podczas jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia
                      Użytkownika.
                    </li>
                    <li>
                      <b>Cookies trwałe</b> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez Serwis do
                      momentu ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia
                      chyba że konfiguracja Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookie po
                      zakończeniu sesji Urządzenia.
                    </li>
                  </ol>
                </li>
                <li>
                  W ramach naszej strony internetowej, stosowane są następujące rodzaje plików cookie ze względu na
                  niezbędność do realizacji usług:
                  <ol className="alpha">
                    <li>
                      niezbędne pliki cookie, umożliwiające korzystanie z usług dostępnych w ramach strony internetowej,
                      w szczególności uwierzytelniające pliki cookie wykorzystywane do usług wymagających
                      uwierzytelnienia;
                    </li>
                    <li>
                      pliki cookie służące do zapewnienia bezpieczeństwa, w szczególności wykorzystywane do wykrywania
                      nadużyć w zakresie uwierzytelniania;
                    </li>
                    <li>
                      wydajnościowe pliki cookie, umożliwiające zbieranie informacji o sposobie korzystania ze stron
                      internetowych;
                    </li>
                    <li>
                      funkcjonalne pliki cookie, umożliwiające „zapamiętanie” wybranych przez użytkownika ustawień i
                      personalizację interfejsu użytkownika;
                    </li>
                    <li>
                      reklamowe pliki cookie, umożliwiające dostarczanie użytkownikom treści reklamowych dostosowanych
                      do ich zainteresowań.
                    </li>
                  </ol>
                </li>
                <li>
                  Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie
                  dopuszcza przechowywanie plików cookie w urządzeniu końcowym. Użytkownik przeglądający stronę
                  internetową może samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików cookie, określając
                  warunki ich przechowywania i uzyskiwania dostępu przez pliki cookie do swojego urządzenia. Zmiany
                  ustawień, o których mowa w zdaniu poprzednim, Klient może dokonać za pomocą ustawień przeglądarki
                  internetowej. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować
                  automatyczną obsługę plików cookie w ustawieniach przeglądarki internetowej bądź informować o ich
                  każdorazowym zamieszczeniu plików cookie na urządzeniu Użytkownika. Szczegółowe informacje o
                  możliwości i sposobach obsługi plików cookie dostępne są w ustawieniach oprogramowania (przeglądarki
                  internetowej).
                </li>
                <li>
                  Korzystanie ze strony internetowej, bez zmiany ustawień w zakresie plików cookie, oznacza wyrażenie
                  zgody na zapisywanie plików cookie. Klienta zawsze może wycofać zgodę poprzez zmianę ustawień
                  dotyczących plików cookie.Informacje w jaki sposób dokonać konfiguracji ustawień plików cookie w
                  przykładowych przeglądarkach internetowych Użytkownik może znaleźć tutaj:
                  <ol className="alpha">
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pl">
                        Chrome
                      </a>
                    </li>
                    <li>
                      <a href="https://help.opera.com/pl/latest/web-preferences/">Opera</a>
                    </li>
                    <li>
                      <a href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek">FireFox</a>
                    </li>
                    <li>
                      <a href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies">Edge</a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">Safari</a>
                    </li>
                    <li>
                      <a href="https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11">
                        Internet Explorer 11
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
              <h4>§ 5 Prawa i obowiązki</h4>
              <ol>
                <li>
                  Mamy prawo a w przypadkach prawem określonych także i ustawowy obowiązek do przekazania wybranych bądź
                  wszystkich informacji dotyczących danych osobowych organom władzy publicznej bądź osobom trzecim,
                  które zgłoszą takie żądanie udzielenia informacji na podstawie obowiązujących przepisów prawa
                  polskiego.
                </li>
                <li>
                  Użytkownik ma prawo do:
                  <ol className="alpha">
                    <li>
                      dostępu do danych osobowych - Użytkownikowi przysługuje prawo uzyskania dostępu do swoich danych
                      osobowych, realizowane na żądanie złożone do Administratora
                    </li>
                    <li>
                      sprostowania danych osobowych - Użytkownikowi przysługuje prawo żądania od Administratora
                      niezwłocznego sprostowania danych osobowych, które są nieprawidłowe lub / oraz uzupełnienia
                      niekompletnych danych osobowych, realizowane na żądanie złożone do Administratora
                    </li>
                    <li>
                      <b>usunięcia danych osobowych</b> - Użytkownikowi przysługuje prawo żądania od Administratora
                      niezwłocznego usunięcia danych osobowych, realizowane na żądanie złożone do Administratora W
                      przypadku kont użytkowników, usunięcie danych polega na anonimizacji danych umożliwiających
                      identyfikację Użytkownika. Administrator zastrzega sobie prawo wstrzymania realizacji żądania
                      usunięcia danych w celu ochrony prawnie uzasadnionego interesu Administratora (np. w gdy
                      Użytkownik dopuścił się naruszenia Regulaminu czy dane zostały pozyskane wskutek prowadzonej
                      korespondencji). W przypadku usługi Newsletter, Użytkownik ma możliwość samodzielnego usunięcia
                      swoich danych osobowych korzystając z odnośnika umieszczonego w każdej przesyłanej wiadomości
                      e-mail.
                    </li>
                    <li>
                      ograniczenia przetwarzania danych osobowych - Użytkownikowi przysługuje prawo ograniczenia
                      przetwarzania danych osobowych w przypadkach wskazanych w art. 18 RODO, m.in. kwestionowania
                      prawidłowość danych osobowych, realizowane na żądanie złożone do Administratora
                    </li>
                    <li>
                      przenoszenia danych osobowych - Użytkownikowi przysługuje prawo uzyskania od Administratora,
                      danych osobowych dotyczących Użytkownika w ustrukturyzowanym, powszechnie używanym formacie
                      nadającym się do odczytu maszynowego, realizowane na żądanie złożone do Administratora
                    </li>
                    <li>
                      wniesienia sprzeciwu wobec przetwarzania danych osobowych - Użytkownikowi przysługuje prawo
                      wniesienia sprzeciwu wobec przetwarzania jego danych osobowych w przypadkach określonych w art. 21
                      RODO, realizowane na żądanie złożone do Administratora
                    </li>
                    <li>
                      wniesienia skargi - Użytkownikowi przysługuje prawo wniesienia skargi do organu nadzorczego
                      zajmującego się ochroną danych osobowych.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Regulamin;
