import React, { useEffect, useRef } from 'react';

import { useSearchStore } from '../store/searchStore';
import Pagination from '../components/search/Pagination';
import HeroArea from '../components/hero-area/HeroArea';
import SearchResults from '../components/search/SearchResults';
import useDocumentTitle from '../hooks/use-document-title';
import { SearchType } from '../components/hero-area/search-form/Enums';

const SearchAddress: React.FC = () => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const {
    isLoading,
    currentAddressSearch,
    searchAddressResults,
    searchAddress,
    scrollToResults,
    setScrollToResults,
    clearSearch,
    clearAddressSearchResults,
  } = useSearchStore((state) => ({
    isLoading: state.isLoading,
    error: state.error,
    currentAddressSearch: state.currentAddressSearch,
    searchAddressResults: state.searchAddressResults,
    currentSearchType: state.currentSearchType,
    searchAddress: state.searchAddress,
    scrollToResults: state.scrollToResults,
    setScrollToResults: state.setScrollToResults,
    clearSearch: state.clearSearch,
    clearAddressSearchResults: state.clearAddressSearchResults,
  }));

  useDocumentTitle('Wyszukaj po adresie');

  useEffect(() => {
    if (scrollToResults && resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setScrollToResults(false);
    }
  }, [scrollToResults, setScrollToResults]);

  useEffect(() => {
    return () => {
      clearSearch();
      clearAddressSearchResults();
    };
  }, []);

  const handlePageChange = (pageNumber: number) => {
    if (currentAddressSearch.city.id !== 0) {
      const params = {
        city: currentAddressSearch.city,
        street: currentAddressSearch.street,
        streetNumber: currentAddressSearch.streetNumber,
        flatNumber: currentAddressSearch.flatNumber,
        page: pageNumber,
      };
      searchAddress(params);
    }
  };

  const useFormToSearch = (
    <div className="row d-flex justify-items-center mt-100 mb-100">
      <h5 className="col-12 text-center">Brak wyników dla danego zapytania</h5>
    </div>
  );

  const renderSearchResults = () => {
    if (isLoading) {
      return (
        <div className="row justify-items-center pt-100">
          <div className="col-12 d-flex flex-column justify-content-center">
            <h5 className="text-center">Ładowanie...</h5>
            <div className="d-flex justify-content-center">
              <span className="search-loader"></span>
            </div>
          </div>
        </div>
      );
    }

    if (currentAddressSearch.city.id === 0 && !searchAddressResults) {
      return useFormToSearch;
    }

    if (searchAddressResults?.status == 'error') {
      return (
        <div className="row d-flex justify-items-center mt-100 mb-100">
          <h5 className="col-12 text-center">Brak wyników dla danego zapytania</h5>
        </div>
      );
    }

    const currentPage = searchAddressResults?.data.pagination.current || 1;
    const totalPages = searchAddressResults?.data.pagination.pages || 1;

    if (searchAddressResults) {
      return (
        <>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          <SearchResults searchType={SearchType.Address} />
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </>
      );
    }

    return useFormToSearch;
  };

  return (
    <>
      <HeroArea isHomeScreen={false} searchType={SearchType.Address} />
      <section className="category-page section search-results pt-0 fill-content" ref={resultsRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="category-grid-list">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="nav-list"
                        role="tabpanel"
                        aria-labelledby="nav-list-tab"
                      >
                        {renderSearchResults()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchAddress;
