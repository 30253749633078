import React, { useEffect } from 'react';
import useOrderStore from '../store/orderStore';
import { concatBookIdentifier, navigateToEKW } from '../helpers/helpers';
import { BookPreviewData } from '../services/previewApi';
import BookTable from '../components/BookTable';
import { Link, useNavigate } from 'react-router-dom';
import useDocumentTitle from '../hooks/use-document-title';

const ThankYou: React.FC = () => {
  const { fulfilledOrder, clearCurrentOrder, clearFullfilledOrder } = useOrderStore();

  const navigate = useNavigate();

  useDocumentTitle('Dziękujemy za zakup');

  const [previewData, setPreviewData] = React.useState<BookPreviewData | null>(null);

  useEffect(() => {
    if (!fulfilledOrder) {
      navigate('/');
    } else {
      setPreviewData(fulfilledOrder?.previewData);
    }
  }, [fulfilledOrder, navigate, previewData]);

  useEffect(() => {
    clearCurrentOrder();
  }, []);

  return (
    <section id="contact-us" className="pt-30 contact-us section fill-content">
      <div className="container ">
        <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-lg-8  col-12">
              <div className="form-main d-flex flex-column">
                <div className="col-12 mb-20 text-center">
                  <h3 className="mb-20">Dziękujemy za zakup!</h3>
                  {previewData && <h5 className="mb-20">{concatBookIdentifier(previewData)}</h5>}
                </div>
                <div className="col-12 col-md-6 mb-20 d-flex flex-column align-self-center align-items-center">
                  <button className="btn col-12 col-md-8 btn-primary " onClick={navigateToEKW}>
                    Zobacz pełną treść księgi
                  </button>
                  <button className="btn col-12 col-md-8 mt-10 btn-link">
                    <Link to="/moje-konto">Przejdź do konta</Link>
                  </button>
                </div>
                {previewData && <BookTable previewData={previewData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
