import React, { useEffect } from 'react';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
import DiscoveredBooks from '../components/dashboard/DiscoveredBooks';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Purchases from '../components/dashboard/Purchases';
import EditAccount from '../components/dashboard/EditAccount';
import useAuthStore from '../store/authStore';
import useDocumentTitle from '../hooks/use-document-title';

const Dashboard: React.FC = () => {
  const { isLoggedIn } = useAuthStore();
  const navigate = useNavigate();
  const validateToken = useAuthStore((state) => state.validateToken);

  useDocumentTitle('Moje konto');

  useEffect(() => {
    validateToken();

    if (!isLoggedIn) {
      navigate('/logowanie');
    }
  }, [isLoggedIn, navigate]);

  return (
    <section className="dashboard section fill-content">
      <div className="pt-60 container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <DashboardSidebar />
          </div>
          <div className="col-lg-12 col-md-12 col-12 mt-20">
            <Routes>
              <Route path="/" element={<DiscoveredBooks />} />
              <Route path="/ksiegi" element={<DiscoveredBooks />} />
              <Route path="/zakupy" element={<Purchases />} />
              <Route path="/edytuj" element={<EditAccount />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
