import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import useDocumentTitle from '../hooks/use-document-title';

const Regulamin: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  useDocumentTitle('Regulamin');

  return (
    <section className="login section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-12">
            <div className="form-head privacy">
              <h4 className="title">Regulamin serwisu internetowego</h4>
              <p>
                Właścicielem Serwisu Internetowego jest Data Mansion LLC z siedzibą w Delaware 651 N Broad Street, Suite
                201, Middletown, Delaware 19709 (dalej: „Sprzedawca” lub „Serwis Internetowy”). Kontakt pod adresem
                kontakt@ksiegiwieczyste.io.
              </p>
              <h4>§ 1 Postanowienia ogólne</h4>
              <ol>
                <li>
                  Niniejszy regulamin określa rodzaje i zakres usług świadczonych elektronicznie oraz warunki ich
                  świadczenia, procedurę składania zamówień, realizacji zamówienia oraz metody płatności.
                </li>
                <li>
                  Regulamin określa także sposób i termin wykonania prawa odstąpienia od umowy oraz procedurę
                  rozpatrywania reklamacji.
                </li>
                <li>
                  Klient zobowiązany jest w szczególności do niedostarczania i nieprzekazywania treści o charakterze
                  bezprawnym, np. treści propagujących przemoc.
                </li>
                <li>
                  W zakresie świadczenia usług elektronicznych zgodnie z ustawą z dnia 18 lipca 2002 o świadczeniu usług
                  drogą elektroniczną regulamin ten reguluje świadczenie usług drogą elektroniczną. Do współpracy z
                  systemem informatycznym wymagane jest spełnienie następujących minimalnych wymagań technicznych
                  korzystanie z przeglądarki internetowej na sprawnym komputerze lub innym urządzeniu, obsługi
                  JavaScript oraz akceptacji plików Cookies.
                </li>
              </ol>
              <h4>§ 2 Zamówienia</h4>
              <ol>
                <li>
                  Składanie zamówienia:
                  <ol className="alpha">
                    <li>Klient powinien wybrać produkt będący przedmiotem zamówienia i kliknąć przycisk „Zakup”;</li>
                    <li>
                      Klient może zalogować się lub zarejestrować w Sklepie Internetowym, a po zakończeniu kompletowania
                      zamówienia kliknąć „Realizuj zamówienie” w koszyku;
                    </li>
                    <li>
                      Klient wybiera sposób płatności, akceptuje warunki regulaminu i klika przycisk „Zapłać” oraz
                      opłaca zamówienie.
                    </li>
                  </ol>
                </li>
                <li>
                  Złożenie zamówienia przez Klienta za pomocą przycisku „Zapłać” lub innego o podobnym znaczeniu jest
                  równoznaczne z zawarciem umowy ze Sprzedającym.
                </li>
              </ol>
              <h4>§ 3 Konto klienta</h4>
              <ol>
                <li>
                  Założenie konta jest bezpłatne polega na wypełnieniu formularza rejestracyjnego. Rejestracja oznacza
                  akceptację niniejszego Regulaminu.
                </li>
                <li>
                  Dane wymagane podczas rejestracji obejmują: imię, nazwisko oraz adres e-mail. Rejestracja w Sklepie
                  Internetowym wymaga zgody na przetwarzanie danych osobowych w celu świadczenia usług elektronicznych,
                  zawierania i wykonywania umów z Klientem, realizacji zamówień oraz rozpatrywania reklamacji i zwrotu
                  świadczeń w przypadku odstąpienia od umowy. Dane podane podczas rejestracji konta mogą być zmieniane
                  lub usuwane przez Klienta po zalogowaniu się na konto.
                </li>
                <li>Wymagane jest podanie hasła w celu zabezpieczenia dostępu do konta.</li>
                <li>
                  Klient może usunąć swoje konto w Sklepie Internetowym w dowolnym momencie, wysyłając informację na
                  podany adres e-mail.
                </li>
              </ol>
              <h4>§ 4 Reklamacje </h4>
              <ol>
                <li>
                  Reklamacje dotyczące usług świadczonych drogą elektroniczną oraz wadliwości usługi można składać na
                  adres e-mail: kontakt@ksiegiwieczyste.io.
                </li>
                <li>
                  Serwis Internetowy wyłącza odpowiedzialność z tytułu rękojmi za wady usługi w przypadku umów
                  zawieranych z podmiotami niebędącymi Konsumentami.
                </li>
                <li>
                  Jeżeli Sprzedający nie dostarczył treści cyfrowej lub usługi cyfrowej, konsument wzywa go do ich
                  dostarczenia. Jeżeli Sprzedający po wezwaniu nie dostarczy treści cyfrowej, konsument może odstąpić od
                  umowy, a jeżeli treść cyfrowa lub usługa cyfrowa są niezgodne z umową, konsument może żądać
                  doprowadzenia ich do zgodności z umową.
                </li>
                <li>
                  Jeżeli treść cyfrowa lub usługa cyfrowa są niezgodne z umową, konsument może złożyć oświadczenie o
                  obniżeniu ceny albo odstąpieniu od umowy w określonych przepisami przypadkach.
                </li>
                <li>
                  Konsument nie może odstąpić od umowy, jeżeli brak zgodności treści cyfrowej lub usługi cyfrowej z
                  umową jest nieistotny.
                </li>
              </ol>
              <h4>§ 5 Prawo odstąpienia</h4>
              <ol>
                <li>
                  Przy zakupie pojedynczej usługi („Odkrycie”), konsumentowi nie przysługuje prawo odstąpienia od umowy.
                  Przy zakupie pakietu usług („Pakiet odkryć”), konsumentowi przysługuje prawo odstąpienia od umowy do
                  momentu rozpoczęcia korzystania z usługi („Pakiet odkryć”).
                </li>
                <li>
                  Konsument może w terminie 14 dni odstąpić od umowy bez podania przyczyny i bez ponoszenia kosztów,
                  składając oświadczenie o odstąpieniu, a umowę uważa się wówczas za niezawartą. W przypadku odstąpienia
                  od umowy konsument musi zaprzestać korzystania z treści cyfrowych lub usług cyfrowych i udostępniania
                  ich osobom trzecim.
                </li>
                <li>
                  Po rozpoczęciu korzystania z usługi („Pakiet odkryć”), czyli dokonaniu chociażby jednego „Odkrycia”,
                  konsumentowi nie przysługuje prawo odstąpienia od umowy, ponieważ jest to równoznaczne z rozpoczęciem
                  przez przedsiębiorcę świadczenia usługi i utratą prawa do odstąpienia przez konsumenta (umowa o
                  dostarczanie treści cyfrowych niedostarczanych na nośniku materialnym).
                </li>
              </ol>
              <h4>§ 6 Klauzula informacyjna - dane osobowe</h4>
              <ol>
                <li>
                  Administratorem danych jest Data Mansion LLC z siedzibą w Delaware 651 N Broad Street, Suite 201,
                  Middletown, Delaware 19709, adres e-mail: kontakt@ksiegiwieczyste.io.
                </li>
                <li>
                  Dane osobowe będą przetwarzane przez administratora w celu świadczenia usług drogą elektroniczną,
                  zawierania i wykonywania umów z Klientem, realizacji zamówień oraz rozpatrywania reklamacji i zwrotu
                  świadczeń w przypadku odstąpienia od umowy oraz ochrony prawnie uzasadnionych interesów
                  administratora;
                </li>
                <li>
                  Przetwarzanie danych osobowych odbywa się na podstawie art. 6 ust. 1 lit. b ww. Rozporządzenia w
                  zakresie wykonania i zawarcia umowy sprzedaży oraz świadczenia usług drogą elektroniczną oraz na
                  podstawie art. 6 ust. 1 lit. f ww. Rozporządzenia jako niezbędne do celów wynikających z prawnie
                  uzasadnionych interesów realizowanych przez administratora takich jak dochodzenie należności
                  wynikających z zawartej umowy;
                </li>
                <li>
                  Odbiorcami danych osobowych będą podmioty uczestniczące w realizacji umowy, w tym pracownicy IT,
                  hostingodawca, kancelarie prawnicze;
                </li>
                <li>
                  Okres przetwarzania trwa do momentu wykonania umowy, a także ustania uzasadnionego interesu
                  realizowanego przez administratora, czyli na przykład przedawnienia roszczeń cywilnoprawnych z tytułu
                  umowy sprzedaży;
                </li>
                <li>
                  Klientowi przysługuje prawo do żądania od administratora dostępu do danych osobowych, ich
                  sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do sprzeciwu wobec przetwarzania oraz
                  prawo do przenoszenia danych oraz prawo do wniesienia skargi do organu nadzorczego;
                </li>
                <li>
                  Podanie danych osobowych jest dobrowolne, jednakże ich brak uniemożliwi zawarcie bądź wykonanie umowy.
                </li>
              </ol>
              <h4>§ 7 Postanowienia końcowe</h4>
              <ol>
                <li>
                  W przypadku sporów dotyczących roszczeń wynikających z zawartej umowy lub reklamacji, strony mogą
                  skorzystać z pozasądowych metod rozwiązywania sporów, w tym z mediacji. Powyższe uprawnienia nie
                  wykluczają możliwości dochodzenia przez strony roszczeń przed sądem na zasadach ogólnych.
                </li>
                <li>
                  Postanowienia dotyczące konsumenta stosuje się również do osoby fizycznej zawierającej umowę
                  bezpośrednio związaną z jej działalnością gospodarczą (przedsiębiorcą), gdy z treści tej umowy wynika,
                  że nie posiada ona charakteru zawodowego, zgodnie z przepisami prawa.
                </li>
                <li>
                  Sprzedający zastrzega sobie prawo do zmiany Regulaminu w przypadku wystąpienia ważnych przyczyn, a
                  informacja o każdej zmianie zostanie umieszczona w Serwisie Internetowym.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Regulamin;
