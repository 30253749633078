// store.ts
import { create } from 'zustand';
import { IPackage, fetchPackages } from '../services/packageApi';

interface StoreState {
  packages: IPackage[];
  loading: boolean;
  error: string | null;
  isMobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
  fetchPackagesAction: () => Promise<void>;
  closeMobileMenu: () => void;
}

const useStore = create<StoreState>((set) => ({
  packages: [],
  loading: false,
  error: null,
  isMobileMenuOpen: false,
  toggleMobileMenu: () => set((state) => ({ isMobileMenuOpen: !state.isMobileMenuOpen })),
  closeMobileMenu: () => set({ isMobileMenuOpen: false }),
  fetchPackagesAction: async () => {
    set({ loading: true, error: null });
    try {
      const packages = await fetchPackages();
      set({ packages, loading: false });
    } catch (error) {
      set({ error: 'Failed to fetch packages', loading: false });
    }
  },
}));

export default useStore;
