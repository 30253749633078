// searchApi.ts
import { number } from 'yup';
import axios from './axios';

export interface Pagination {
  total: number;
  totalFull: number;
  perPage: number;
  pages: number;
  current: number;
}

export interface Book {
  id: number;
  department: string;
  register: string;
  control_number: string;
}

export interface SearchFlat {
  flat_number: string;
  area: string;
  book: Book;
}

export interface AddressResult {
  id: number;
  street: string;
  city: string;
  number: string;
  lot_id: number | null;
  prg_lot_id: number;
  zip_code: string;
  area: string;
  book: Book | null;
  flats: SearchFlat[];
}

export interface LotResult {
  id: number;
  identifier: string;
  number: string;
  center: string;
  area: string;
  prg_lot_id: number;
  streets: string | null;
  book: Book | null;
}

export interface SearchAddressResponse {
  status: string;
  data: {
    pagination: Pagination;
    results: AddressResult[];
  };
}

export interface SearchLotResponse {
  status: string;
  data: {
    pagination: Pagination;
    results: LotResult[];
  };
}

export const searchAddress = async (params: {
  cityId: number;
  streetId?: number;
  streetNumber?: string;
  flatNumber?: string;
  page: number;
}): Promise<SearchAddressResponse> => {
  try {
    const response = await axios.get('/search/address', {
      params: params,
    });
    const data = response.data;

    if (data.status === 'success') {
      return data;
    } else {
      console.error('Failed to search address:', data);
      return {
        status: 'error',
        data: {
          pagination: {
            total: 0,
            totalFull: 0,
            perPage: 0,
            pages: 0,
            current: 0,
          },
          results: [],
        },
      };
    }
  } catch (error) {
    console.error('Error searching address:', error);
    return {
      status: 'error',
      data: {
        pagination: {
          total: 0,
          totalFull: 0,
          perPage: 0,
          pages: 0,
          current: 0,
        },
        results: [],
      },
    };
  }
};

export const searchLot = async (params: {
  identifier?: string;
  cityId?: number;
  street?: string;
  number?: string;
  page?: number;
}): Promise<SearchLotResponse> => {
  try {
    const requestParams = {
      identifier: params.identifier || '',
      cityId: params.cityId || '',
      street: params.street || '',
      number: params.number || '',
      page: params.page || '',
    };

    const response = await axios.get('/search/lot', {
      params: requestParams,
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      console.error('Failed to search lot:', response.data);
      return {
        status: 'error',
        data: {
          pagination: {
            total: 0,
            totalFull: 0,
            perPage: 0,
            pages: 0,
            current: 0,
          },
          results: [],
        },
      };
    }
  } catch (error) {
    console.error('Error searching lot:', error);
    return {
      status: 'error',
      data: {
        pagination: {
          total: 0,
          totalFull: 0,
          perPage: 0,
          pages: 0,
          current: 0,
        },
        results: [],
      },
    };
  }
};
