import React, { RefObject, forwardRef, useRef } from 'react';
import PackagesTable from './PackagesTable';

interface PricingSectionProps {
  ref?: RefObject<HTMLDivElement>;
}

const PricingSection = forwardRef<HTMLDivElement, PricingSectionProps>((props, ref) => {
  return (
    <section ref={ref} className="pricing-table section pt-40" id="pricing">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h2 className="wow fadeInUp" data-wow-delay=".4s">
                Nasze pakiety
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Wybierz pakiet, który najlepiej pasuje do Twoich potrzeb.
              </p>
            </div>
          </div>
        </div>
        <PackagesTable />
      </div>
    </section>
  );
});

PricingSection.displayName = 'PricingSection';

export default PricingSection;
