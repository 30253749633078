import axios from './axios';

export interface Stats {
  books: number;
  buildings: number;
  flats: number;
  lots: number;
  updatedAt: Date;
}

export const fetchStats = async (): Promise<Stats | null> => {
  try {
    const response = await axios.get('/stats');
    const data = response.data;
    if (response.status === 200) {
      return data;
    } else {
      console.error('Failed to fetch stats:', data);
      return null;
    }
  } catch (error) {
    console.error('Error fetching stats:', error);
    return null;
  }
};
