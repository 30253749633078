import { BookPreviewData } from '../services/previewApi';

export const concatBookIdentifier = (previewData: BookPreviewData): string => {
  return `${previewData.department}/${previewData.register}/${previewData.control_number}`;
};

export const navigateToEKW = () => {
  window.open('https://przegladarka-ekw.ms.gov.pl/eukw_prz/KsiegiWieczyste/wyszukiwanieKW', '_blank');
};

function padZero(number: number) {
  return number.toString().padStart(2, '0');
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const formattedDate = `${padZero(date.getDate())}-${padZero(date.getMonth() + 1)}-${date.getFullYear()} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
  return formattedDate;
};
