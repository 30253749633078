import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { BookPreviewData } from '../services/previewApi';
import { purchaseBookWithToken } from '../services/purchaseApi';

interface GuestOrderState {
  lookupToken: string | null;
  setLookupToken: (token: string) => void;
  bookId: number | null;
  setBookId: (id: number) => void;
  email: string | null;
  setEmail: (email: string) => void;
  previewData: BookPreviewData | null;
  setPreviewData: (data: BookPreviewData) => void;
  purchaseBook: () => Promise<void>;
}

const useGuestOrderStore = create(
  persist<GuestOrderState>(
    (set, get) => ({
      lookupToken: null,
      setLookupToken: (token) => set({ lookupToken: token }),
      bookId: null,
      setBookId: (id) => set({ bookId: id }),
      previewData: null,
      setPreviewData: (data) => set({ previewData: data }),
      email: null,
      setEmail: (email: string) => set({ email }),
      purchaseBook: async () => {
        const { bookId, lookupToken, email } = get();
        if (!bookId || !lookupToken || !email) {
          console.error('Missing required information for purchase');
          return;
        }
        try {
          const response = await purchaseBookWithToken(bookId, lookupToken, email);
          if (response.status === 'success') {
            set({ previewData: response.data });
          } else {
            console.error('Purchase failed:', response.error);
          }
        } catch (error) {
          console.error('Error during purchase:', error);
        }
      },
    }),
    {
      name: 'guest-order-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useGuestOrderStore;
