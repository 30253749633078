import React from 'react';
import HeroText from './HeroText';
import SearchForm from './search-form/SearchForm';
import { SearchType } from './search-form/Enums';

interface IProps {
  isHomeScreen: boolean;
  searchType?: SearchType;
}
const HeroArea: React.FC<IProps> = ({ isHomeScreen, searchType }) => {
  return (
    <section className="hero-area style2 overlay">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12 col-12">
            <HeroText />
          </div>
          <div className="col-lg-5 col-md-12 col-12">
            <SearchForm isHomeScreen={isHomeScreen} searchType={searchType} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroArea;
