import React, { useState, useRef, useCallback, useEffect } from 'react';
import SearchSuggestions from './SearchSuggestions';
import { useSuggestionStore } from '../../../../store/suggestionStore';
import Tippy from '@tippyjs/react';
import { useSearchStore } from '../../../../store/searchStore';

interface SearchInputWithSuggestionProps<T extends object> {
  placeholder: string;
  onSelect: (value: T) => void;
  fetchSuggestions: (query: string) => Promise<T[]>;
  disabled?: boolean;
  debounceDelay?: number;
  fillInput?: string;
  onInputChange?: () => void;
  initialValue?: T;
  clearable?: boolean;
}

const SearchInputWithSuggestion = <T extends object>({
  placeholder,
  onSelect,
  fetchSuggestions,
  disabled = false,
  debounceDelay = 300,
  onInputChange,
  initialValue,
  clearable = false,
}: SearchInputWithSuggestionProps<T>) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const fetchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const setIsLoading = useSuggestionStore((state) => state.setIsLoading);
  const componentRef = useRef<HTMLDivElement>(null);
  const clearSuggestionInput = useSearchStore((state) => state.clearSuggestionInput);
  const setClearSuggestionInput = useSearchStore((state) => state.setClearSuggestionInput);

  useEffect(() => {
    if (initialValue) {
      const displayValue = getDisplayValue(initialValue);
      setInputValue(displayValue);

      if (displayValue.length >= 2) {
        const fetchInitialSuggestions = async () => {
          try {
            const fetchedSuggestions = await fetchSuggestions(displayValue);
            setSuggestions(fetchedSuggestions);
          } catch (error) {
            console.error('Error fetching initial suggestions:', error);
          }
        };

        fetchInitialSuggestions();
      }
    }
  }, [initialValue]);

  useEffect(() => {
    if (clearable && clearSuggestionInput) {
      setInputValue('');
      setClearSuggestionInput(false);
    }
  }, [clearable, clearSuggestionInput, setClearSuggestionInput]);

  const handleInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsFocused(true);
      const value = event.target.value;
      setInputValue(value);
      setActiveIndex(-1);

      onInputChange && onInputChange();

      if (value.length >= 2) {
        setIsLoading(true);

        if (fetchTimeoutRef.current) {
          clearTimeout(fetchTimeoutRef.current);
        }

        fetchTimeoutRef.current = setTimeout(async () => {
          try {
            const fetchedSuggestions = await fetchSuggestions(value);
            setSuggestions(fetchedSuggestions);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching suggestions:', error);
            setIsLoading(false);
          }
        }, debounceDelay);
      } else {
        setSuggestions([]);
      }
    },
    [fetchSuggestions, onSelect, debounceDelay],
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setActiveIndex((prevIndex) => (prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0));
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1));
    } else if (event.key === 'Enter') {
      if (activeIndex !== -1) {
        onSelect(suggestions[activeIndex]);
        setInputValue(getDisplayValue(suggestions[activeIndex]));
        setIsFocused(false);
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setIsFocused(false);
    }
  };

  const handleSuggestionClick = (suggestion: T) => {
    setTimeout(() => {
      setInputValue(getDisplayValue(suggestion));
      onSelect(suggestion);
      setIsFocused(false);
    }, 100);
  };

  const getDisplayValue = (suggestion: T): string => {
    if ('street' in suggestion) {
      const streetFullName = `${(suggestion as any).street}`;
      return streetFullName;
    } else if ('county' in suggestion) {
      return (suggestion as any).city;
    }
    return '';
  };

  const handleInputFocus = async () => {
    setIsFocused(true);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        setIsFocused(false);
      }
    },
    [componentRef],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Tippy content={'Wybierz miejscowość'} disabled={!disabled}>
      <div className="search-input" ref={componentRef}>
        <input
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          disabled={disabled}
          onFocus={handleInputFocus}
          onBlur={() => setIsFocused(false)}
        />

        {isFocused && (
          <SearchSuggestions<T>
            isInputEmpty={inputValue.length < 2}
            suggestions={suggestions}
            activeIndex={activeIndex}
            onSuggestionClick={handleSuggestionClick}
          />
        )}
      </div>
    </Tippy>
  );
};

export default SearchInputWithSuggestion;
