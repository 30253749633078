import React from 'react';

const HeroText: React.FC = () => {
  return (
    <div className="hero-text wow fadeInLeft" data-wow-delay=".3s">
      <div className="section-heading">
        <h2>KsiegiWieczyste.io</h2>
        <p>
          Największa baza 24 milionów ksiąg wieczystych. <br />
          Znajdź księgę wieczystą dowolnej nieruchomości na podstawie jej adresu lub numeru działki.
        </p>
      </div>
    </div>
  );
};

export default HeroText;
