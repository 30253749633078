import React, { useState } from 'react';
import { useSearchStore } from '../../store/searchStore';
import AddressBookItem from './AddressBookitem';
import LotBookItem from './LotBookItem';
import { SearchType } from '../hero-area/search-form/Enums';

interface IProps {
  searchType: SearchType;
}

const SearchResults: React.FC<IProps> = ({ searchType }) => {
  const searchAddressResults = useSearchStore((state) => state.searchAddressResults);
  const searchLotResults = useSearchStore((state) => state.searchLotResults);

  if (!searchAddressResults && !searchLotResults) {
    return <div>No search results available</div>;
  }

  if (searchType == 'lot' && searchLotResults) {
    return (
      <div className="row">
        {searchLotResults.data.results.map((result) => (
          <div key={result.id} className="col-lg-12 col-md-12 col-12">
            <LotBookItem result={result} />
          </div>
        ))}
      </div>
    );
  }

  if (searchType == 'address' && searchAddressResults) {
    return (
      <div className="row">
        {searchAddressResults.data.results.map((result) => (
          <div key={result.id} className="col-lg-12 col-md-12 col-12">
            <AddressBookItem result={result} />
          </div>
        ))}
      </div>
    );
  }
};

export default SearchResults;
