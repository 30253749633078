// previewApi.ts
import axios from './axios';

export interface Location {
  province: string;
  county: string;
  commune: string;
  city: string;
}

export interface Lot {
  identifier: string;
  number: string;
  area: string;
  precinct_name: string;
  street: string;
}

export interface Building {
  area: string;
  street: string;
  street_number: string;
}

export interface PreviewFlat {
  area: string;
  street: string;
  street_number: string;
  flat_number: string;
}

export interface BookPreviewData {
  id: number;
  book_id: number;
  purchased: boolean;
  department: string;
  register: string;
  control_number: string;
  locations: Location[];
  lots: Lot[];
  buildings: Building[];
  flats: PreviewFlat[];
}

export interface PreviewResponse {
  status: string;
  data: BookPreviewData;
}

export const fetchPreview = async (id: number): Promise<BookPreviewData> => {
  try {
    const response = await axios.get(`/preview/${id}`);
    const data = response.data as PreviewResponse;

    if (data.status === 'success') {
      return data.data;
    } else {
      console.error('Failed to fetch preview:', data);
      throw new Error('Failed to fetch preview');
    }
  } catch (error) {
    console.error('Error fetching preview:', error);
    throw error;
  }
};
