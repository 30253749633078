import React from 'react';
import useDocumentTitle from '../hooks/use-document-title';

const ContactUs: React.FC = () => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  useDocumentTitle('Kontakt');

  return (
    <section id="contact-us" className="contact-us section min-vh-60 fill-content">
      <div className="container ">
        <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="form-main">
                <div className="form-title">
                  <h2>Skontaktuj się z nami</h2>
                </div>
                <div>
                  Zapraszamy do kontaktu z nami na adres email:{' '}
                  <a href="mailto:kontakt@ksiegiwieczyste.io">kontakt@ksiegiwieczyste.io</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
