import React from 'react';

import kwLogo from '../../_assets/images/logo/kw.io.svg';
import logoNew from '../../_assets/images/logo/logo-new.svg';
import { Link } from 'react-router-dom';

const Logo: React.FC = () => {
  return (
    <Link className="navbar-brand" to="/">
      <img src={logoNew} alt="Logo" />
    </Link>
  );
};

export default Logo;
