// api.ts
import axios from './axios';

export interface City {
  id: number;
  city: string;
  commune: string;
  county: string;
  province: string;
}

export interface Street {
  id: number;
  type: string;
  street: string;
  city: string;
}

export const fetchCitySuggestions = async (query: string): Promise<City[]> => {
  try {
    const response = await axios.get(`/suggestion/city/${query}`);
    const data = response.data;

    if (data.status === 'success') {
      return data.data;
    } else {
      console.error('Failed to fetch city suggestions:', data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching city suggestions:', error);
    return [];
  }
};

export const fetchStreetSuggestions = async (selectedCity: number, query: string): Promise<Street[]> => {
  try {
    const response = await axios.get(`/suggestion/street/${selectedCity}/${query}`);
    const data = response.data;

    if (data.status === 'success') {
      return data.data;
    } else {
      console.error('Failed to fetch street suggestions:', data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching street suggestions:', error);
    return [];
  }
};
