import React, { useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import useStore from '../../store/store';
import useAuthStore from '../../store/authStore';

const Navigation: React.FC = () => {
  const { isMobileMenuOpen, toggleMobileMenu, closeMobileMenu } = useStore();
  const location = useLocation();
  const { isLoggedIn, logout } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    // Close the menu when the window is resized
    const handleResize = () => {
      closeMobileMenu();
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [closeMobileMenu]);

  useEffect(() => {
    // Close the menu when the location changes (redirect)
    closeMobileMenu();
  }, [location, closeMobileMenu]);

  const onLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      <button
        className="navbar-toggler mobile-menu-btn"
        type="button"
        onClick={toggleMobileMenu}
        aria-label="Toggle navigation"
      >
        <span className="toggler-icon"></span>
        <span className="toggler-icon"></span>
        <span className="toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse sub-menu-bar ${isMobileMenuOpen ? 'show' : ''}`}
        id="navbarSupportedContent"
      >
        <ul id="nav" className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link to="/ksiegi-wieczyste-po-adresie" aria-label="Toggle navigation">
              Wyszukaj po adresie
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/ksiegi-wieczyste-po-nr-dzialki" aria-label="Toggle navigation">
              Wyszukaj po nr działki
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/#pakiety" aria-label="Toggle navigation">
              Pakiety
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/kontakt" aria-label="Toggle navigation">
              Kontakt
            </Link>
          </li>
          {isMobileMenuOpen && (
            <>
              {isLoggedIn ? (
                <>
                  <li className="nav-item d-flex align-items-center">
                    <NavLink to="/moje-konto/ksiegi" aria-label="Toggle navigation">
                      <i className="lni lni-user me-2"></i>
                      Moje konto
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-items-center">
                    <NavLink to="/" aria-label="Toggle navigation" onClick={onLogout}>
                      <i className="lni lni-exit me-2"></i>
                      Wyloguj
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item d-flex align-items-center">
                    <NavLink to="/logowanie" aria-label="Toggle navigation">
                      <i className="lni lni-enter me-2"></i>
                      Logowanie
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-items-center">
                    <NavLink to="/rejestracja" aria-label="Toggle navigation">
                      <i className="lni lni-user me-2"></i>
                      Rejestracja
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default Navigation;
