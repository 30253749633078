import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTransactionStore } from '../../store/transactionStore';
import useAuthStore from '../../store/authStore';
import useGuestOrderStore from '../../store/guestOrderStore';
import BookTable from '../BookTable';
import { concatBookIdentifier, navigateToEKW } from '../../helpers/helpers';

export const Return = () => {
  const [session, setSession] = useState({
    id: null,
    status: null,
    amount: null,
  });
  const { fetchSessionStatus } = useTransactionStore();
  const { lookupToken, setLookupToken, setEmail, purchaseBook, previewData } = useGuestOrderStore();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const interval = setInterval(() => {
    if (session.status) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16677566212/RhyxCMqIkcwZEITuvZA-',
        value: session.amount,
        currency: 'PLN',
        transaction_id: session.id,
      });

      clearInterval(interval);
    }
  }, 100);

  useEffect(() => {
    const fetchData = async () => {
      const queryString = new URLSearchParams(location.search);
      const sessionId = queryString.get('session_id');

      if (sessionId) {
        const { session, lookupToken, customer_email } = await fetchSessionStatus(sessionId);
        setSession(session);
        setEmail(customer_email);
        setLookupToken(lookupToken);
      }
    };

    fetchData();
  }, [fetchSessionStatus, location.search]);

  useEffect(() => {
    if (lookupToken) {
      purchaseBook();
    }
  }, [lookupToken, navigate]);

  const handleContinue = () => {
    navigate('/');
  };

  if (session.status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (session.status === 'complete') {
    if (isLoggedIn) {
      return (
        <section id="contact-us" className="contact-us section min-vh-60 fill-content">
          <div className="container ">
            <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-6 col-12">
                  <div className="form-main">
                    <div className="form-title">
                      <h2>Dziękujemy za zakup!</h2>
                    </div>
                    <div>
                      <div>Odkrycia pomyślnie zostały dodane do Państwa konta.</div>
                      <br /> Aby skorzystać z pakietu należy odszukać KW, kliknąć Zakup, a nastepnie przejść przez
                      formularz i zapłacić dostępnymi odkryciami.
                    </div>
                    <div className="mt-20 d-flex justify-content-center">
                      <button className="btn btn-primary" onClick={handleContinue}>
                        Dalej
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <section id="contact-us" className="contact-us section min-vh-60 fill-content">
          <div className="container ">
            <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-lg-6 col-12">
                  <div className="form-main">
                    <div className="form-title text-center">
                      <h2>Dziękujemy za zakup!</h2>
                    </div>
                    {previewData && (
                      <div className="text-center">
                        <h4>{concatBookIdentifier(previewData)}</h4>
                      </div>
                    )}
                    <div className="col-12 col-md-12 mb-20 d-flex flex-column align-self-center align-items-center">
                      <button className="btn col-12 col-md-8 btn-primary " onClick={navigateToEKW}>
                        Zobacz pełną treść księgi
                      </button>
                    </div>
                    {previewData && <BookTable previewData={previewData} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
};
