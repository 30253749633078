import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { City, Street } from '../services/suggestionApi';

interface SuggestionState {
  selectedCity: City | null;
  setSelectedCity: (city: City | null) => void;
  selectedStreet: Street | null;
  setSelectedStreet: (street: Street | null) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const useSuggestionStore = create<SuggestionState>()((set) => ({
  selectedCity: null,
  setSelectedCity: (city) => set({ selectedCity: city }),
  selectedStreet: null,
  setSelectedStreet: (street) => set({ selectedStreet: street }),
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
}));
