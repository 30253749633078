import React, { forwardRef } from 'react';

import styles from '../SearchForm.module.scss';

interface SearchButtonProps {
  onClick?: () => void;
  isDisabled?: boolean;
}

const SearchButton = forwardRef<HTMLDivElement, SearchButtonProps>(({ onClick, isDisabled }, ref) => {
  return (
    <div ref={ref} className={`button ${isDisabled ? styles.cursorBlocked : ''}`}>
      <button className="btn" onClick={onClick} disabled={isDisabled}>
        <i className="lni lni-search-alt"></i> Szukaj
      </button>
    </div>
  );
});

SearchButton.displayName = 'SearchButton';

export default SearchButton;
