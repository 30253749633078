import React, { useEffect, useState, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/authApi';
import useAuthStore from '../../store/authStore';

import styles from './LoginForm.module.scss';

interface LoginFormProps {
  setShowForgotPassword: Dispatch<React.SetStateAction<boolean>>;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setShowForgotPassword } = props;
  const { login, loginAdditionalInfo, setLoginAdditionalInfo, customRedirect, setCustomRedirect } = useAuthStore();
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [redirectTo, setRedirectTo] = useState('');

  useEffect(() => {
    if (loginAdditionalInfo) {
      setAdditionalInfo(loginAdditionalInfo);
      setLoginAdditionalInfo('');
    }
  }, [loginAdditionalInfo]);

  useEffect(() => {
    if (customRedirect) {
      setRedirectTo(customRedirect);
      setCustomRedirect('');
    }
  }, [customRedirect]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = await loginUser(email, password);
      login(token);
      if (rememberMe) {
        localStorage.setItem('token', token);
      } else {
        sessionStorage.setItem('token', token);
      }

      if (redirectTo) {
        const [path, hash] = redirectTo.split('#');
        navigate(path);
        if (hash) {
          window.location.hash = `#${hash}`;
        }
      } else {
        navigate('/');
      }
    } catch (error) {
      setError('Nieprawidłowy email lub hasło.');
    }
  };

  const clearError = () => {
    setError('');
  };

  const showForgotPasswordForm = () => {
    setShowForgotPassword(true);
  };

  return (
    <>
      <h4 className="title">Logowanie</h4>
      <form onSubmit={handleSubmit}>
        {additionalInfo && !error && (
          <div className={styles.error}>
            <p>{additionalInfo}</p>
          </div>
        )}
        {error && (
          <div className={styles.error}>
            <p>{error}</p>
          </div>
        )}
        <div className="form-group">
          <label>Email</label>
          <input
            name="email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              clearError();
            }}
            required
          />
        </div>
        <div className="form-group">
          <label>Hasło</label>
          <input
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="check-and-pass">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input width-auto"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={(e) => {
                    setRememberMe(e.target.checked);
                    clearError();
                  }}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Zapamiętaj mnie
                </label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <a onClick={showForgotPasswordForm} className="lost-pass">
                Zapomniałeś hasła?
              </a>
            </div>
          </div>
        </div>
        <div className="button">
          <button type="submit" className="btn">
            Zaloguj
          </button>
        </div>
        <p className="outer-link">
          Nie masz jeszcze konta? <a href="/rejestracja">Zarejestruj się tutaj</a>
        </p>
      </form>
    </>
  );
};

export default LoginForm;
