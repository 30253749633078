import React, { useEffect } from 'react';
import Package from './Package';
import { IPackage } from '../../services/packageApi';

const PackagesEmptyState: React.FC = () => {
  const emptyPackages: IPackage[] = Array.from({ length: 3 }, (_, index) => ({
    name: '',
    price: '',
    lookupsCount: index * 10,
    id: '',
    priceId: '',
  }));

  return (
    <div className="row">
      {emptyPackages.map((plan, index) => (
        <div key={index} className="col-lg-4 col-md-4 col-12">
          <Package {...plan} isBlurred={true} />
        </div>
      ))}
    </div>
  );
};

export default PackagesEmptyState;
