import axios from './axios';

interface Location {
  province: string;
  county: string;
  commune: string;
  city: string;
}

export interface UserBook {
  bookId: number;
  bookNumber: string;
  locations: Location[];
  addresses: string[];
  lotNumbers: string[];
  createdAt: string;
}

export interface FetchUserLookupsResponse {
  data: {
    lookups: number;
  };
}

export interface FetchUserBooksResponse {
  data: UserBook[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
}

export interface UserPurchase {
  productName: string;
  price: number;
  currency: string;
  tokens: number;
  purchaseDate: string;
}

export interface FetchUserPurchasesResponse {
  data: UserPurchase[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
}

export interface FetchUserDetailsResponse {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface UserDetailsPayload {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

interface GetIsBookPurchasedResponse {
  purchased: boolean;
}

export const fetchUserLookups = async (): Promise<number> => {
  const response: FetchUserLookupsResponse = await axios.get('/user/me/lookups');
  return response.data.lookups;
};

export const fetchUserBooks = async (page = 1): Promise<FetchUserBooksResponse> => {
  const response = await axios.get(`/user/me/books?page=${page}`);
  return response.data;
};

export const fetchUserPurchases = async (): Promise<FetchUserPurchasesResponse> => {
  const response = await axios.get('/user/me/purchases');
  return response.data;
};

export const fetchUserDetails = async (): Promise<FetchUserDetailsResponse> => {
  const response = await axios.get('/user/me');
  return response.data;
};

export const saveUserDetails = async (userDetails: UserDetailsPayload): Promise<FetchUserDetailsResponse> => {
  const response = await axios.put('/user/me', userDetails);
  return response.data;
};

export const getIsBookPurchased = async (bookId: number): Promise<GetIsBookPurchasedResponse> => {
  const response = await axios.get(`/user/me/purchases/${bookId}`);
  return response.data;
};
