import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/authStore';
import useDocumentTitle from '../hooks/use-document-title';

const RegisterSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn, registrationSuccess, setRegistrationSuccess } = useAuthStore();

  useDocumentTitle('Rejestracja');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!registrationSuccess) {
      navigate('/rejestracja');
      setRegistrationSuccess(false);
    }
  }, []);

  return (
    <section className="login registration section fill-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
            <div className="form-head">
              <h4 className="title">Rejestracja pomyślna</h4>
              <div className="d-flex flex-column justify-content-center">
                <p className="text-center">
                  <span>Dziękujemy za rejestrację.</span>
                  <span> Teraz możesz zalogować się na swoje konto.</span>
                </p>
                <div className="d-flex justify-content-center mt-20">
                  <button className="btn btn-primary" onClick={() => navigate('/logowanie')}>
                    Przejdź do logowania
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterSuccess;
