import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  siblingCount?: number;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange, siblingCount = 2 }) => {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    const startPage = Math.max(1, currentPage - siblingCount);
    const endPage = Math.min(totalPages, currentPage + siblingCount);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={currentPage === i ? 'active' : ''}>
          <a href="javascript:void(0)" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>,
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <li key="ellipsis-start">
          <span>...</span>
        </li>,
      );
      pageNumbers.unshift(
        <li key={1}>
          <a href="javascript:void(0)" onClick={() => handlePageChange(1)}>
            1
          </a>
        </li>,
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="ellipsis-end">
          <span>...</span>
        </li>,
      );
      pageNumbers.push(
        <li key={totalPages}>
          <a href="javascript:void(0)" onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </a>
        </li>,
      );
    }

    return pageNumbers;
  };

  if (totalPages === 1 || totalPages === 0) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="pagination left">
          <ul className="pagination-list">
            {currentPage > 1 && (
              <li>
                <a onClick={() => handlePageChange(currentPage - 1)}>
                  <i className="lni lni-chevron-left"></i>
                </a>
              </li>
            )}
            {renderPageNumbers()}
            {currentPage < totalPages && (
              <li>
                <a onClick={() => handlePageChange(currentPage + 1)}>
                  <i className="lni lni-chevron-right"></i>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
