import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import AuthButtons from './AuthButtons';
import '../../scss/_header.scss';

const Header: React.FC = () => {
  return (
    <header className="header navbar-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg">
                <Logo />
                <Navigation />
                <AuthButtons />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
