import React, { useState } from 'react';
import { SearchFlat as Flat } from '../../services/searchApi';

import styles from './FlatItem.module.scss';
import usePreviewStore from '../../store/previewStore';
import DetailsModal from '../details-modal/DetailsModal';
import { useNavigate } from 'react-router-dom';
import useOrderStore from '../../store/orderStore';
import { isBook } from './helpers';

interface FlatItemProps {
  flat: Flat;
}

const FlatItem: React.FC<FlatItemProps> = ({ flat }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { setCurrentOrder } = useOrderStore();
  const isBookDiscovered = !flat.book.register.includes('x');

  const { previewData, fetchPreview } = usePreviewStore();

  const onBuyBookClick = () => {
    if (isBookDiscovered) {
      return null;
    }

    if (isBook(flat.book)) {
      setCurrentOrder(flat.book.id);
      return navigate('/zamowienie');
    }
  };

  const handleDetailsClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (flat.book) {
      await fetchPreview(flat.book.id);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`flat-item ${styles.flatItem}`}>
      <div className="row align-items-center g-0">
        <div className="col-2 pl-10">
          <h5 className="title">{flat.flat_number}</h5>
        </div>
        <div className="col-4">
          <p className="location">
            {flat.area} m<sup>2</sup>
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          {flat.book ? (
            <div className="buttons d-flex justify-content-end">
              <div className="row">
                <div className="col-12 col-md-6 mb-2 mb-md-0 d-flex justify-content-md-end justify-content-end">
                  <button className="btn btn-secondary" onClick={handleDetailsClick}>
                    Szczegóły
                  </button>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-md-end justify-content-end">
                  <button
                    className={`btn btn-primary ${styles.bookButton}`}
                    onClick={onBuyBookClick}
                  >{`${flat.book.department}/${flat.book.register}/${flat.book.control_number}`}</button>
                </div>
              </div>
            </div>
          ) : (
            <div className="buttons d-flex justify-content-center">
              <p>Brak księgi</p>
            </div>
          )}
        </div>
      </div>
      {previewData && <DetailsModal isOpen={isModalOpen} closeModal={closeModal} previewData={previewData} />}
    </div>
  );
};

export default FlatItem;
