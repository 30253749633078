import React, { useEffect } from 'react';
import Package from './Package';
import useStore from '../../store/store';
import PackagesEmptyState from './PackagesEmptyState';

const PackagesTable: React.FC = () => {
  const packages = useStore((state) => state.packages);
  const loading = useStore((state) => state.loading);
  const error = useStore((state) => state.error);
  const fetchPackagesAction = useStore((state) => state.fetchPackagesAction);

  useEffect(() => {
    fetchPackagesAction();
  }, []);

  if (loading) {
    return <PackagesEmptyState />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <div className="row">
      {packages.map((plan, index) => (
        <div key={index} className="col-lg-4 col-md-4 col-12">
          <Package {...plan} />
        </div>
      ))}
    </div>
  );
};

export default PackagesTable;
