import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { SearchAddressResponse, SearchLotResponse, searchAddress, searchLot } from '../services/searchApi';
import { City, Street } from '../services/suggestionApi';

export const emptyCity: City = {
  id: 0,
  city: '',
  commune: '',
  county: '',
  province: '',
};

export const emptyStreet: Street = {
  id: 0,
  type: '',
  street: '',
  city: '',
};

const emptyAddressSearch = {
  city: emptyCity,
  street: emptyStreet,
  streetNumber: '',
  flatNumber: '',
  page: 1,
};

const emptyLotSearch = {
  identifier: '',
  city: emptyCity,
  street: '',
  number: '',
  page: 0,
};

interface SearchState {
  setScrollToResults: any;
  currentSearchType: 'lot' | 'address' | null;
  currentAddressSearch: {
    city: City;
    street?: Street;
    streetNumber: string;
    flatNumber: string;
    page: number;
  };
  currentLotSearch: {
    identifier: string;
    city: City | null;
    street: string;
    number: string;
    page: number;
  };
  searchAddressResults: SearchAddressResponse | null;
  searchLotResults: SearchLotResponse | null;
  isLoading: boolean;
  error: string | null;
  searchLot: (params: {
    identifier: string;
    city: City | null;
    street: string;
    number: string;
    page: number;
  }) => Promise<void>;
  searchAddress: (params: {
    city: City;
    street?: Street;
    streetNumber: string;
    flatNumber: string;
    page: number;
  }) => Promise<void>;
  scrollToResults: boolean;
  streetInputValue: string;
  setStreetInputValue: (value: string) => void;
  setCurrentAddressSearchCity: (city: City) => void;
  setCurrentAddressSearchStreet: (street?: Street) => void;
  setCurrentAddressSearchStreetNumber: (streetNumber: string) => void;
  setCurrentAddressSearchFlatNumber: (flatNumber: string) => void;
  setCurrentLotSearchIdentifier: (identifier: string) => void;
  setCurrentLotSearchCity: (city: City | null) => void;
  setCurrentLotSearchStreet: (street: string) => void;
  setCurrentLotSearchLotNumber: (lotNumber: string) => void;
  clearSearch: () => void;
  setClearSuggestionInput: (value: boolean) => void;
  clearSuggestionInput: boolean;
  clearAddressSearchResults: () => void;
  clearLotSearchResults: () => void;
}

export const useSearchStore = create<SearchState>()((set) => ({
  searchAddressResults: null,
  searchLotResults: null,
  isLoading: false,
  error: null,
  currentSearchType: null,
  currentAddressSearch: emptyAddressSearch,
  currentLotSearch: emptyLotSearch,
  streetInputValue: '',
  setStreetInputValue: (value: string) => set({ streetInputValue: value }),
  searchLot: async (params: {
    identifier: string;
    city: City | null;
    street: string;
    number: string;
    page: number;
  }) => {
    set({ currentSearchType: 'lot' });
    set({ searchLotResults: null });
    set({ searchAddressResults: null });
    set({ currentLotSearch: params });
    set({ isLoading: true, error: null });
    const requestParams = {
      ...params,
      cityId: params.city?.id,
    };

    try {
      const response = await searchLot(requestParams);
      set({ searchLotResults: response, isLoading: false });
    } catch (error) {
      set({ error: 'Failed to search lot', isLoading: false });
    }
  },
  searchAddress: async (params: {
    city: City;
    street?: Street;
    streetNumber: string;
    flatNumber: string;
    page: number;
  }) => {
    const requestParams = {
      cityId: params.city.id,
      streetId: params.street?.id,
      streetNumber: params.streetNumber,
      flatNumber: params.flatNumber,
      page: params.page,
    };

    set({ currentSearchType: 'address' });
    set({ currentAddressSearch: params });
    set({ searchAddressResults: null });
    set({ searchLotResults: null });
    set({ isLoading: true, error: null });
    try {
      const response = await searchAddress(requestParams);
      set({ searchAddressResults: response, isLoading: false });
    } catch (error) {
      set({ error: 'Failed to search address', isLoading: false });
    }
  },
  scrollToResults: false,
  setScrollToResults: (value: boolean) => set({ scrollToResults: value }),
  clearSearch: () => {
    set({
      currentAddressSearch: emptyAddressSearch,
      currentLotSearch: emptyLotSearch,
    });
  },
  setCurrentAddressSearchCity: (city: City) =>
    set((state) => ({
      currentAddressSearch: { ...state.currentAddressSearch, city },
    })),
  setCurrentAddressSearchStreet: (street?: Street) =>
    set((state) => ({
      currentAddressSearch: { ...state.currentAddressSearch, street: street || undefined },
    })),
  setCurrentAddressSearchStreetNumber: (streetNumber: string) =>
    set((state) => ({
      currentAddressSearch: { ...state.currentAddressSearch, streetNumber },
    })),
  setCurrentAddressSearchFlatNumber: (flatNumber: string) =>
    set((state) => ({
      currentAddressSearch: { ...state.currentAddressSearch, flatNumber },
    })),

  setCurrentLotSearchIdentifier: (identifier: string) =>
    set((state) => ({
      currentLotSearch: { ...state.currentLotSearch, identifier },
    })),
  setCurrentLotSearchCity: (city: City | null) =>
    set((state) => ({
      currentLotSearch: { ...state.currentLotSearch, city },
    })),
  setCurrentLotSearchStreet: (street: string) =>
    set((state) => ({
      currentLotSearch: { ...state.currentLotSearch, street },
    })),
  setCurrentLotSearchLotNumber: (number: string) =>
    set((state) => ({
      currentLotSearch: { ...state.currentLotSearch, number },
    })),
  clearSuggestionInput: false,
  setClearSuggestionInput: (value: boolean) => set({ clearSuggestionInput: value }),
  clearAddressSearchResults: () => {
    set({ searchAddressResults: null });
  },
  clearLotSearchResults: () => {
    set({ searchLotResults: null });
  },
}));
