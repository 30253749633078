import { create } from 'zustand';
import { BookPreviewData, fetchPreview } from '../services/previewApi';

interface PreviewState {
  previewData: BookPreviewData | null;
  isLoading: boolean;
  error: string | null;
  fetchPreview: (bookId: number) => Promise<void>;
}

const usePreviewStore = create<PreviewState>((set) => ({
  previewData: null,
  isLoading: false,
  error: null,
  fetchPreview: async (bookId) => {
    set({ isLoading: true, error: null });
    try {
      const data = await fetchPreview(bookId);
      set({ previewData: data, isLoading: false });
    } catch (error) {
      set({ error: 'Failed to fetch preview data', isLoading: false });
    }
  },
}));

export default usePreviewStore;
