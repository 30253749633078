import React, { useEffect, useState } from 'react';
import { useUserStore } from '../../store/userStore';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import useAuthStore from '../../store/authStore';
import { AxiosError } from 'axios';

const EditAccountSchema = Yup.object().shape({
  firstName: Yup.string().required('Imię jest wymagane'),
  lastName: Yup.string().required('Nazwisko jest wymagane'),
  email: Yup.string().email('Nieprawidłowy email').required('Email jest wymagany'),
});

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Aktualne hasło jest wymagane'),
  newPassword: Yup.string().min(6, 'Hasło musi mieć co najmniej 6 znaków').required('Nowe hasło jest wymagane'),
  retypePassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Hasła muszą się zgadzać')
    .required('Powtórz nowe hasło jest wymagane'),
});

const EditAccount: React.FC = () => {
  const { userDetails, fetchUserDetails, saveUserDetails, saveUserDetailsError } = useUserStore();
  const { changePassword } = useAuthStore(); // Extract the changePassword method from authStore
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserDetails();
    };

    fetchData();
  }, [fetchUserDetails]);

  useEffect(() => {
    if (userDetails) {
      setInitialValues({
        firstName: userDetails.firstName || '',
        lastName: userDetails.lastName || '',
        phone: userDetails.phone || '',
        email: userDetails.email || '',
      });
    }
  }, [userDetails]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      await saveUserDetails(values);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Failed to save user details', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handlePasswordChange = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      await changePassword(values.currentPassword, values.newPassword);
      setShowPasswordSuccess(true);
      setPasswordError('');
      resetForm();
      setTimeout(() => {
        setShowPasswordSuccess(false);
      }, 5000);
    } catch (error) {
      console.error('Failed to change password', error);
      if (error instanceof AxiosError) {
        setPasswordError(error.response?.data.msg);
      } else {
        setPasswordError('Nie udało się zmienić hasła. Spróbuj ponownie.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="main-content">
      <div className="dashboard-block mt-0 profile-settings-block">
        <h3 className="block-title">Dane konta</h3>
        <div className="inner-block">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={EditAccountSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form className="profile-setting-form">
                {saveUserDetailsError && (
                  <div className="alert alert-danger" role="alert">
                    {saveUserDetailsError}
                  </div>
                )}
                {showSuccess && (
                  <div className="alert alert-success" role="alert">
                    Pomyślnie zapisano zmiany.
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="firstName">Imię*</label>
                      <Field type="text" name="firstName" className="form-control" />
                      <ErrorMessage name="firstName" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="lastName">Nazwisko*</label>
                      <Field type="text" name="lastName" className="form-control" />
                      <ErrorMessage name="lastName" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="phone">Numer telefonu</label>
                      <Field type="text" name="phone" className="form-control" />
                      <ErrorMessage name="phone" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field type="email" name="email" className="form-control" />
                      <ErrorMessage name="email" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group button mb-0">
                      <button type="submit" className="btn" disabled={!dirty || isSubmitting || !isValid}>
                        Zapisz
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* End Profile Settings Area */}
      {/* Start Password Change Area */}
      <div className="dashboard-block password-change-block">
        <h3 className="block-title">Zmiana hasła</h3>
        <div className="inner-block">
          <Formik
            initialValues={{ currentPassword: '', newPassword: '', retypePassword: '' }}
            validationSchema={ChangePasswordSchema}
            onSubmit={handlePasswordChange}
          >
            {({ isSubmitting, dirty, isValid }) => (
              <Form className="default-form-style">
                {passwordError && (
                  <div className="alert alert-danger" role="alert">
                    {passwordError}
                  </div>
                )}
                {showPasswordSuccess && (
                  <div className="alert alert-success" role="alert">
                    Hasło zostało zmienione pomyślnie.
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="currentPassword">Aktualne hasło*</label>
                      <Field
                        type="password"
                        name="currentPassword"
                        placeholder="Podaj aktualne hasło"
                        className="form-control"
                      />
                      <ErrorMessage name="currentPassword" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="newPassword">Nowe hasło*</label>
                      <Field
                        type="password"
                        name="newPassword"
                        placeholder="Podaj nowe hasło"
                        className="form-control"
                      />
                      <ErrorMessage name="newPassword" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="retypePassword">Powtórz nowe hasło*</label>
                      <Field
                        type="password"
                        name="retypePassword"
                        placeholder="Powtórz nowe hasło"
                        className="form-control"
                      />
                      <ErrorMessage name="retypePassword" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group button mb-0">
                      <button type="submit" className="btn" disabled={!dirty || isSubmitting || !isValid}>
                        Zapisz zmiany
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditAccount;
