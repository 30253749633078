import { AxiosError } from 'axios';
import { useSearchStore } from '../store/searchStore';
import axios from './axios';
import { BookPreviewData } from './previewApi';

export interface PurchaseResponse {
  status: string;
  data: BookPreviewData | null;
  error: string | null;
}

export const purchaseBook = async (bookId: number): Promise<PurchaseResponse> => {
  try {
    const response = await axios.post(`/purchase/${bookId}`);
    return { status: 'success', data: response.data, error: null };
  } catch (error) {
    console.error('Error purchasing book:', error);
    if (error instanceof AxiosError) {
      return { status: 'error', data: null, error: error.response?.data };
    } else {
      return { status: 'error', data: null, error: 'Coś poszło nie tak. Spróbuj ponownie.' };
    }
  }
};

export const purchaseBookWithToken = async (
  bookId: number,
  token: string,
  email: string,
): Promise<PurchaseResponse> => {
  try {
    const response = await axios.post(`/purchase/token/${bookId}`, { lookupToken: token, email });
    return { status: 'success', data: response.data, error: null };
  } catch (error) {
    console.error('Error purchasing book with token:', error);
    if (error instanceof AxiosError) {
      return { status: 'error', data: null, error: error.response?.data };
    } else {
      return { status: 'error', data: null, error: 'Coś poszło nie tak. Spróbuj ponownie.' };
    }
  }
};
