import React from 'react';
import Tippy from '@tippyjs/react';

interface SearchInputProps {
  placeholder: string;
  icon?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, icon, disabled = false, value, onChange }) => {
  return (
    <Tippy content={'Wybierz miejscowość'} disabled={!disabled}>
      <div className="search-input">
        <label htmlFor="keyword">{icon && <i className={`${icon} theme-color`}></i>}</label>
        <input
          type="text"
          name="keyword"
          id="keyword"
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
      </div>
    </Tippy>
  );
};

export default SearchInput;
