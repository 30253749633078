import React, { useState } from 'react';
import { UserBook } from '../../services/userApi';

import { formatDate } from '../../helpers/helpers';
import { BookPreviewData, fetchPreview } from '../../services/previewApi';
import DetailsModal from '../details-modal/DetailsModal';

interface UserBookListItemProps {
  book: UserBook;
}

const UserBookListItem: React.FC<UserBookListItemProps> = ({ book }) => {
  const formattedDate = formatDate(book.createdAt);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewData, setBookPreviewData] = useState<BookPreviewData | null>(null);

  const handleDetailsClick = async () => {
    if (book) {
      const data = await fetchPreview(book.bookId);
      setBookPreviewData(data);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="single-item-list">
      <div className="row align-items-center">
        <div className="col-6 col-md-2">
          <p>{book.bookNumber}</p>
        </div>
        <div className="col-2 d-md-block d-none">
          {book.addresses.map((address, index) => (
            <p key={index}>{address}</p>
          ))}
        </div>
        <div className="col-2 d-md-block d-none">
          {book.locations.map((location, index) => (
            <p key={index}>
              woj. {location.province}, pow. {location.commune}, gm. {location.county}, {location.city}
            </p>
          ))}
        </div>
        <div className="col-2 d-md-block d-none">{book.lotNumbers.join(', ')}</div>
        <div className="col-6 col-md-2">{formattedDate}</div>
        <div className="col-12 col-md-2">
          <button className="btn btn-primary" onClick={handleDetailsClick}>
            Szczegóły
          </button>
        </div>
      </div>

      {previewData && <DetailsModal isOpen={isModalOpen} closeModal={closeModal} previewData={previewData} />}
    </div>
  );
};

export default UserBookListItem;
