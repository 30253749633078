import React from 'react';
import { City, Street } from '../../../../services/suggestionApi';
import { useSuggestionStore } from '../../../../store/suggestionStore';

interface SearchSuggestionsProps<T extends object> {
  suggestions: T[];
  activeIndex: number;
  isInputEmpty: boolean;
  onSuggestionClick: (suggestion: T) => void;
}

// Type guard to check if a suggestion is a City
const isCity = (suggestion: any): suggestion is City => {
  return 'commune' in suggestion && 'county' in suggestion && 'province' in suggestion;
};

// Type guard to check if a suggestion is a Street
const isStreet = (suggestion: any): suggestion is Street => {
  return 'street' in suggestion && 'type' in suggestion;
};

const SearchSuggestions = <T extends object>({
  suggestions,
  activeIndex,
  isInputEmpty,
  onSuggestionClick,
}: SearchSuggestionsProps<T>) => {
  const isLoading = useSuggestionStore((state) => state.isLoading);

  if (isLoading) {
    return (
      <ul className="search-suggestions">
        <li className="loading">Ładowanie...</li>
      </ul>
    );
  }

  if (isInputEmpty) {
    return (
      <ul className="search-suggestions">
        <li className="no-suggestions">Wpisz conajmniej 2 znaki</li>
      </ul>
    );
  }

  if (suggestions.length === 0) {
    return (
      <ul className="search-suggestions">
        <li className="no-suggestions">Brak wyników wyszukiwania</li>
      </ul>
    );
  }

  return (
    <ul className="search-suggestions">
      {suggestions.map((suggestion, index) => (
        <li
          key={index}
          className={index === activeIndex ? 'active' : ''}
          onMouseDown={(event) => {
            event.preventDefault();
            onSuggestionClick(suggestion);
          }}
        >
          {isCity(suggestion) ? (
            <>
              {suggestion.city}
              <br />
              gmin. {suggestion.commune}, pow. {suggestion.county} woj. {suggestion.province}
            </>
          ) : isStreet(suggestion) ? (
            <>
              {suggestion.type} {suggestion.street}
              <br />
              {suggestion.city}
            </>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

export default SearchSuggestions;
