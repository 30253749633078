import React from 'react';
import { BookPreviewData } from '../services/previewApi';

interface BookTableProps {
  previewData: BookPreviewData;
}

const BookTable: React.FC<BookTableProps> = ({ previewData }) => {
  return (
    <div>
      <table className="bookTable table">
        <thead>
          <tr>
            <th colSpan={3} className="heading-1">
              Rubryka 1.3 - Położenie
            </th>
          </tr>
        </thead>
        {previewData.locations.length === 0 ? (
          <tr>
            <td colSpan={3} className="no-data">
              Brak wpisu
            </td>
          </tr>
        ) : (
          <tbody>
            <tr className="heading-2">
              <td width="10%">Lp.</td>
              <td width="40%">Numer i nazwa pola</td>
              <td width="50%">Treść pola</td>
            </tr>
            {previewData.locations.map((location, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td rowSpan={5}>{index + 1}</td>
                  <td>1. Numer porządkowy</td>
                  <td className="data-content">{index + 1}</td>
                </tr>
                <tr>
                  <td>2. Województwo</td>
                  <td className="data-content">{location.province}</td>
                </tr>
                <tr>
                  <td>3. Powiat</td>
                  <td className="data-content">{location.county}</td>
                </tr>
                <tr>
                  <td>4. Gmina</td>
                  <td className="data-content">{location.commune}</td>
                </tr>
                <tr>
                  <td>5. Miejscowość</td>
                  <td className="data-content">{location.city}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>
      <table className="bookTable table">
        <thead>
          <tr>
            <th colSpan={3} className="heading-1">
              Podrubryka 1.4.1 - Działka ewidencyjna
            </th>
          </tr>
        </thead>
        {previewData.lots.length === 0 ? (
          <tr>
            <td colSpan={3} className="no-data">
              Brak wpisu
            </td>
          </tr>
        ) : (
          <tbody>
            <tr className="heading-2">
              <td width="10%">Lp.</td>
              <td width="40%">Numer i nazwa pola</td>
              <td width="50%">Treść pola</td>
            </tr>
            {previewData.lots.map((lot, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td rowSpan={5}>{index + 1}</td>
                  <td>1. Identyfikator działki</td>
                  <td className="data-content">{lot.identifier}</td>
                </tr>
                <tr>
                  <td>2. Numer działki</td>
                  <td className="data-content">{lot.number}</td>
                </tr>
                <tr>
                  <td>3. Obręb ewidencyjny</td>
                  <td className="data-content">{lot.precinct_name}</td>
                </tr>
                <tr>
                  <td>4. Ulica</td>
                  <td className="data-content">{lot.street}</td>
                </tr>
                <tr>
                  <td>5. Obszar</td>
                  <td className="data-content">{lot.area}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>
      <table className="bookTable table">
        <thead>
          <tr>
            <th colSpan={3} className="heading-1">
              Podrubryka 1.4.2 - Budynek
            </th>
          </tr>
        </thead>
        {previewData.buildings.length === 0 ? (
          <tr>
            <td colSpan={3} className="no-data">
              Brak wpisu
            </td>
          </tr>
        ) : (
          <tbody>
            <tr className="heading-2">
              <td width="10%">Lp.</td>
              <td width="40%">Numer i nazwa pola</td>
              <td width="50%">Treść pola</td>
            </tr>
            {previewData.buildings.map((building, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td rowSpan={3}>{index + 1}</td>
                  <td>Area</td>
                  <td className="data-content">{building.area}</td>
                </tr>
                <tr>
                  <td>Street</td>
                  <td className="data-content">{building.street}</td>
                </tr>
                <tr>
                  <td>Street Number</td>
                  <td className="data-content">{building.street_number}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>
      <table className="bookTable table">
        <thead>
          <tr>
            <th colSpan={3} className="heading-1">
              Podrubryka 1.4.4 - Lokal
            </th>
          </tr>
        </thead>
        {previewData.flats.length === 0 ? (
          <tr>
            <td colSpan={3} className="no-data">
              Brak wpisu
            </td>
          </tr>
        ) : (
          <tbody>
            <tr className="heading-2">
              <td width="10%">Lp.</td>
              <td width="40%">Numer i nazwa pola</td>
              <td width="50%">Treść pola</td>
            </tr>
            {previewData.flats.map((flat, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td rowSpan={4}>{index + 1}</td>
                  <td>Ulica</td>
                  <td className="data-content">{flat.street}</td>
                </tr>
                <tr>
                  <td>Numer budynku</td>
                  <td className="data-content">{flat.street_number}</td>
                </tr>
                <tr>
                  <td>Numer lokalu</td>
                  <td className="data-content">{flat.flat_number}</td>
                </tr>
                <tr>
                  <td>Obszar</td>
                  <td className="data-content">{flat.area}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        )}
      </table>{' '}
    </div>
  );
};

export default BookTable;
