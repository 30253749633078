import { create } from 'zustand';
import axios from '../services/axios';

interface SessionStatusResponse {
  session: {
    id: string;
    status: string;
    amount: number;
  };
  customer_email: string;
  lookupToken: string;
}

interface TransactionState {
  transactionId: string | null;
  setTransactionId: (transactionId: string) => void;
  fetchClientSecret: (priceId: string) => Promise<string>;
  fetchSessionStatus: (sessionId: string) => Promise<any>;
}

export const useTransactionStore = create<TransactionState>((set) => ({
  transactionId: null,
  setTransactionId: (transactionId) => set({ transactionId }),
  fetchClientSecret: async (priceId: string): Promise<string> => {
    const response = await axios.post('/transaction/create-checkout-session', {
      priceId,
    });
    return response.data.clientSecret;
  },
  createGuestSession: async (priceId: string, bookId: number): Promise<string> => {
    const response = await axios.post('/transaction/create-guest-session', {
      priceId,
      bookId,
    });
    return response.data.sessionId;
  },
  fetchSessionStatus: async (sessionId: string): Promise<SessionStatusResponse> => {
    const response = await axios.get(`/transaction/session-status?session_id=${sessionId}`);
    return response.data;
  },
}));
