import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY as string);

import styles from './CheckoutForm.module.scss';
import { useTransactionStore } from '../../store/transactionStore';
import useDocumentTitle from '../../hooks/use-document-title';

export const CheckoutForm: React.FC = () => {
  const { transactionId, fetchClientSecret } = useTransactionStore();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const navigate = useNavigate();

  useDocumentTitle('Płatność');

  useEffect(() => {
    const getClientSecret = async () => {
      if (transactionId) {
        const secret = await fetchClientSecret(transactionId);
        setClientSecret(secret);
      } else {
        navigate('/');
      }
    };
    getClientSecret();
  }, [fetchClientSecret, transactionId]);

  const options = { clientSecret };

  return (
    <div id="checkout" className={`${styles.checkoutForm} fill-content`}>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};
