import { Book } from '../../services/searchApi';

export function isBook(value: any): value is Book {
  return (
    value &&
    typeof value.id === 'number' &&
    typeof value.department === 'string' &&
    typeof value.register === 'string' &&
    typeof value.control_number === 'string'
  );
}

export function formatArea(area: string): string {
  // Remove any non-digit characters except period and comma
  const cleanedArea = area ? area.replace(/[^\d.,]/g, '') : '';

  // Replace comma with period if it exists
  const normalizedArea = cleanedArea.replace(',', '.');

  // Convert the string to a number
  const areaNumber = parseFloat(normalizedArea);

  // Format the number with two digits after the decimal point
  const formattedArea = areaNumber.toFixed(2);

  return formattedArea;
}
