import React, { Dispatch, useState } from 'react';

interface ForgotPasswordFormProps {
  setShowForgotPassword: Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const [email, setEmail] = useState('');

  const goBackToLogin = () => {
    props.setShowForgotPassword(false);
  };

  return (
    <>
      <h4 className="title">Przywracanie hasła</h4>
      <p className="subtitle">
        Podaj adres email na który zostało założone konto aby otrzymać wiadomość z linkiem resetującym hasło
      </p>
      <form>
        <div className="form-group">
          <label>Email</label>
          <input name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="button">
          <button type="submit" className="btn">
            Wyślij
          </button>
        </div>
        <p className="outer-link">
          <a onClick={goBackToLogin}>Wróć do logowania</a>
        </p>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
