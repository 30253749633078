import axios from './axios';

export interface IPackage {
  name: string;
  price: string;
  lookupsCount: number;
  id: string;
  priceId: string;
}

export const fetchPackages = async (): Promise<IPackage[]> => {
  const response = await axios.get('/package');
  return response.data;
};
