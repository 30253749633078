import React, { useState } from 'react';
import { UserBook, UserPurchase } from '../../services/userApi';
import { formatDate } from '../../helpers/helpers';

interface UserPurchaseListItemProps {
  purchase: UserPurchase;
}

const UserPurchaseListItem: React.FC<UserPurchaseListItemProps> = ({ purchase }) => {
  const formattedDate = formatDate(purchase.purchaseDate);

  return (
    <div className="single-item-list">
      <div className="row align-items-center">
        <div className="col-6 col-md-2">
          <p>{purchase.productName}</p>
        </div>
        <div className="col-4 d-md-block d-none">
          <div>{purchase.price}</div>
        </div>
        <div className="col-4 col-md-2">{formattedDate}</div>
        <div className="col-12 col-md-2">{/* Invoice */}</div>
      </div>
    </div>
  );
};

export default UserPurchaseListItem;
