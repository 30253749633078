import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import { useUserStore } from '../../store/userStore';

const AuthButtons: React.FC = () => {
  const { isLoggedIn } = useAuthStore();

  const { logout } = useAuthStore();

  useEffect(() => {
    if (isLoggedIn) {
      useUserStore.getState().fetchUserLookupsAction();
    }
  }, [isLoggedIn]);

  const userLookups = useUserStore((state) => state.userLookups);

  return (
    <div className="login-button">
      <ul>
        {!isLoggedIn ? (
          <>
            <li>
              <Link to="/logowanie">
                <i className="lni lni-enter"></i> Logowanie
              </Link>
            </li>
            <li>
              <Link to="/rejestracja">
                <i className="lni lni-user"></i> Rejestracja
              </Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <li>
                <i className="lni lni-magnifier "></i> {userLookups}
              </li>
              <Link to="/moje-konto/ksiegi">
                <i className="lni lni-user"></i>
                Moje konto
              </Link>
            </li>
            <li>
              <Link to="/" onClick={logout}>
                <i className="lni lni-exit"></i>
                Wyloguj
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default AuthButtons;
