import React, { useEffect, useState } from 'react';

import styles from './SearchForm.module.scss';

import { useSearchStore } from '../../../store/searchStore';
import { SearchType } from './Enums';
import AddressSearchForm from './components/AddressSearchForm';
import LotSearchForm from './components/LotSearchForm';

interface IProps {
  isHomeScreen: boolean;
  searchType?: SearchType;
}

const SearchForm: React.FC<IProps> = ({ isHomeScreen, searchType }) => {
  const [activeTab, setActiveTab] = useState(searchType || 'address');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const { currentSearchType } = useSearchStore.getState();

  useEffect(() => {
    if (currentSearchType === 'lot') {
      setActiveTab('lot');
    }
  }, [currentSearchType]);

  let headingText = 'Wyszukaj księgi po:';
  if (searchType === 'lot') {
    headingText = 'Wyszukaj księgi po numerze działki:';
  } else if (searchType === 'address') {
    headingText = 'Wyszukaj księgi po adresie:';
  }

  const renderSearchForm = () => {
    if (isHomeScreen) {
      if (activeTab === 'address') {
        return <AddressSearchForm />;
      }
      if (activeTab === 'lot') {
        return <LotSearchForm />;
      }
    } else {
      if (searchType === 'address') {
        return <AddressSearchForm />;
      }
      if (searchType === 'lot') {
        return <LotSearchForm />;
      }
    }
  };

  return (
    <div>
      <div className={styles.searchHeading}>
        <h3 className="heading-title">{headingText}</h3>
      </div>
      {isHomeScreen && (
        <div className={`row ${styles.searchTabs} g-0 `}>
          <div
            className={`col-6  d-flex align-items-end justify-content-center ${styles.tab} ${activeTab === 'address' ? styles.active : ''}`}
            onClick={() => handleTabClick('address')}
          >
            <h5>Adresie</h5>
          </div>
          <div
            className={`col-6 d-flex align-items-end justify-content-center ${styles.tab} ${activeTab === 'lot' ? styles.active : ''}`}
            onClick={() => handleTabClick('lot')}
          >
            <h5>Nr działki</h5>
          </div>
        </div>
      )}
      <div
        className={`${styles.searchForm} ${isHomeScreen && styles.searchFormHome} search-form style2 wow fadeInRight`}
        data-wow-delay=".5s"
      >
        {renderSearchForm()}
      </div>
    </div>
  );
};

export default SearchForm;
