import React, { useEffect, useState } from 'react';
import SearchInputWithSuggestion from './SearchInputWithSuggestion';
import { City, fetchCitySuggestions } from '../../../../services/suggestionApi';
import SearchInput from './SearchInput';
import SearchButton from './SearchButton';

import styles from '../SearchForm.module.scss';
import { useNavigate } from 'react-router-dom';
import { emptyCity, useSearchStore } from '../../../../store/searchStore';

import Tippy from '@tippyjs/react';

const LotSearchForm: React.FC = () => {
  const setScrollToResults = useSearchStore((state) => state.setScrollToResults);

  const selectedCity = useSearchStore((state) => state.currentLotSearch.city);
  const setCity = useSearchStore((state) => state.setCurrentLotSearchCity);
  const searchLot = useSearchStore((state) => state.searchLot);
  const selectedStreet = useSearchStore((state) => state.currentLotSearch.street);
  const setSelectedStreet = useSearchStore((state) => state.setCurrentLotSearchStreet);
  const lotIdentifier = useSearchStore((state) => state.currentLotSearch.identifier);
  const setLotIdentifier = useSearchStore((state) => state.setCurrentLotSearchIdentifier);
  const lotNumber = useSearchStore((state) => state.currentLotSearch.number);
  const setLotNumber = useSearchStore((state) => state.setCurrentLotSearchLotNumber);
  const [initialCityValue, setInitialCityValue] = useState<City | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCity) {
      setInitialCityValue(selectedCity);
    }
  }, [selectedCity]);

  const handleSearch = () => {
    if (lotIdentifier || selectedCity) {
      const params = {
        identifier: lotIdentifier,
        city: selectedCity,
        street: selectedStreet,
        number: lotNumber,
        page: 1,
      };
      setScrollToResults(true);
      navigate('/ksiegi-wieczyste-po-nr-dzialki');

      searchLot(params);
    }
  };

  const onCityInputChange = () => {
    setCity(emptyCity);
    setSelectedStreet('');
    setLotNumber('');
  };

  const isCitySelected = selectedCity?.id !== 0;
  const isSearchButtonDisabled = !lotIdentifier && !isCitySelected;

  return (
    <div className={`row ${styles.searchInputs}`}>
      <div className="col-12">
        <p className={`${styles.inputHeading}`}>Według identyfikatora:</p>
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Identyfikator"
          value={lotIdentifier}
          onChange={(e) => {
            setLotIdentifier(e.target.value);
          }}
        />
      </div>
      <div className="col-12">
        <p className={`${styles.inputHeading}`}>Według adresu:</p>
      </div>
      <div className="col-12">
        <SearchInputWithSuggestion<City>
          placeholder="Miasto"
          onSelect={setCity}
          fetchSuggestions={fetchCitySuggestions}
          onInputChange={onCityInputChange}
          initialValue={initialCityValue}
        />
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Ulica (opcjonalnie)"
          disabled={!isCitySelected}
          value={selectedStreet}
          onChange={(e) => setSelectedStreet(e.target.value)}
        />
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Numer działki"
          disabled={!isCitySelected}
          value={lotNumber}
          onChange={(e) => setLotNumber(e.target.value)}
        />
      </div>
      <div className="col-12">
        <Tippy content="Wpisz identyfikator lub wybierz miejscowość" disabled={!!selectedCity || !!lotIdentifier}>
          <SearchButton onClick={handleSearch} isDisabled={isSearchButtonDisabled} />
        </Tippy>
      </div>
    </div>
  );
};

export default LotSearchForm;
