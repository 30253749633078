import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RegistrationForm from '../components/RegistrationForm';
import useAuthStore from '../store/authStore';
import useDocumentTitle from '../hooks/use-document-title';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  useDocumentTitle('Rejestracja');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  return (
    <section className="login registration section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
            <div className="form-head">
              <h4 className="title">Rejestracja</h4>
              <RegistrationForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
