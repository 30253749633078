import React, { useEffect } from 'react';
import { useUserStore } from '../../store/userStore';
import Pagination from '../search/Pagination';
import UserBookListItem from './UserBookListItem';

const DiscoveredBooks: React.FC = () => {
  const { fetchUserBooks, userBooksResults, booksLoading } = useUserStore();

  useEffect(() => {
    fetchUserBooks(1);
  }, [fetchUserBooks]);

  return (
    <div className="main-content">
      <div className="dashboard-block mt-0">
        <h3 className="block-title mb-0">Odkryte księgi</h3>
        {booksLoading ? (
          <div className="col-12 text-center mt-40 mb-20">
            <h5 className="empty-state-header">Ładowanie...</h5>
          </div>
        ) : (
          <div className="my-items">
            {userBooksResults && (
              <Pagination
                currentPage={userBooksResults.currentPage}
                totalPages={userBooksResults.totalPages}
                onPageChange={fetchUserBooks}
              />
            )}
            <div className="item-list-title mt-20">
              <div className="row align-items-center">
                <div className="col-6 col-md-2">
                  <p>KW</p>
                </div>
                <div className="col-2 d-none d-md-block">
                  <p>Miejscowość</p>
                </div>
                <div className="col-2 d-none d-md-block">
                  <p>Adres</p>
                </div>
                <div className="col-2 d-none d-md-block">
                  <p>Numer działki</p>
                </div>
                <div className="col-6 col-md-2">
                  <p>Data dodania</p>
                </div>
                <div className="col-2" />
              </div>
            </div>
            {userBooksResults?.data.map((book) => <UserBookListItem key={book.bookId} book={book} />)}
            {userBooksResults && (
              <Pagination
                currentPage={userBooksResults.currentPage}
                totalPages={userBooksResults.totalPages}
                onPageChange={fetchUserBooks}
              />
            )}
            {userBooksResults?.data.length === 0 && (
              <div className="col-12 text-center mt-40 mb-20">
                <h5 className="empty-state-header">Brak ksiąg do wyświetlenia</h5>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscoveredBooks;
