// RegistrationForm.tsx
import React from 'react';
import useAuthStore from '../store/authStore';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../services/authApi';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().required('Imię jest wymagane'),
  lastName: Yup.string().required('Nazwisko jest wymagane'),
  email: Yup.string().email('Nieprawidłowy email').required('Email jest wymagany'),
  password: Yup.string().min(6, 'Hasło musi mieć co najmniej 6 znaków').required('Hasło jest wymagane'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Hasła muszą być takie same')
    .required('Potwierdzenie hasła jest wymagane'),
});

const RegistrationForm: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | null>(null);
  const { setRegistrationSuccess } = useAuthStore();

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const { email, password, firstName, lastName, phone } = values;
      const token = await registerUser({ email, password, firstName, lastName, phone });
      setRegistrationSuccess(true);
      navigate('/rejestracja/sukces');
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.msg === 'User already exists') {
          setError('Konto z podanym adresem email już istnieje.');
          return;
        }
      }
      window.scrollTo(0, 0);
      console.error('Registration failed:', error);
      setError('Rejestracja nie powiodła się. Spróbuj ponownie.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {error && <div className="alert alert-danger text-center">{error}</div>}
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          password: '',
          confirmPassword: '',
          agreeTerms: false,
        }}
        validationSchema={RegistrationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <Field type="email" name="email" className="form-control" />
              <ErrorMessage name="email" component="div" className="text-danger" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Hasło*</label>
              <Field type="password" name="password" className="form-control" />
              <ErrorMessage name="password" component="div" className="text-danger" />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Potwierdź hasło*</label>
              <Field type="password" name="confirmPassword" className="form-control" />
              <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">Imię*</label>
              <Field type="text" name="firstName" className="form-control" />
              <ErrorMessage name="firstName" component="div" className="text-danger" />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Nazwisko*</label>
              <Field type="text" name="lastName" className="form-control" />
              <ErrorMessage name="lastName" component="div" className="text-danger" />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Numer telefonu</label>
              <Field type="text" name="phone" className="form-control" />
              <ErrorMessage name="phone" component="div" className="text-danger" />
            </div>
            <div className="button">
              <button type="submit" className="btn" disabled={isSubmitting}>
                Rejestracja
              </button>
            </div>
            <p className="outer-link">
              Masz już konto? <Link to="/logowanie">Zaloguj się teraz</Link>
            </p>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RegistrationForm;
