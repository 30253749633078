import React, { useEffect, useRef } from 'react';

import { useSearchStore } from '../store/searchStore';
import Pagination from '../components/search/Pagination';
import HeroArea from '../components/hero-area/HeroArea';
import SearchResults from '../components/search/SearchResults';
import useDocumentTitle from '../hooks/use-document-title';
import { SearchType } from '../components/hero-area/search-form/Enums';

const SearchLot: React.FC = () => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const {
    isLoading,
    currentLotSearch,
    searchLotResults,
    searchLot,
    scrollToResults,
    setScrollToResults,
    clearLotSearchResults,
    clearSearch,
  } = useSearchStore((state) => ({
    isLoading: state.isLoading,
    error: state.error,
    currentLotSearch: state.currentLotSearch,
    searchLotResults: state.searchLotResults,
    currentSearchType: state.currentSearchType,
    searchLot: state.searchLot,
    scrollToResults: state.scrollToResults,
    setScrollToResults: state.setScrollToResults,
    clearLotSearchResults: state.clearLotSearchResults,
    clearSearch: state.clearSearch,
  }));

  const title = 'Wyszukiwanie po numerze księgi wieczystej';

  useDocumentTitle(title);

  useEffect(() => {
    return () => {
      clearSearch();
      clearLotSearchResults();
    };
  }, []);

  useEffect(() => {
    if (scrollToResults && resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setScrollToResults(false);
    }
  }, [scrollToResults, setScrollToResults]);

  const handlePageChange = (pageNumber: number) => {
    if (currentLotSearch.identifier !== '' || currentLotSearch.city) {
      const params = {
        identifier: currentLotSearch.identifier,
        city: currentLotSearch.city,
        street: currentLotSearch.street,
        number: currentLotSearch.number,
        page: pageNumber,
      };
      searchLot(params);
    }
  };

  const useSearchForm = (
    <div className="row d-flex justify-items-center mt-100 mb-100">
      <h5 className="col-12 text-center">Użyj formularza powyżej aby wyszukać księgi</h5>
    </div>
  );

  const renderSearchResults = () => {
    if (isLoading) {
      return (
        <div className="row justify-items-center pt-100">
          <div className="col-12 d-flex flex-column justify-content-center">
            <h5 className="text-center">Ładowanie...</h5>
            <div className="d-flex justify-content-center">
              <span className="search-loader"></span>
            </div>
          </div>
        </div>
      );
    }

    if (currentLotSearch.identifier === '' && !currentLotSearch.city) {
      return useSearchForm;
    }

    if (searchLotResults?.status == 'error') {
      return (
        <div className="row d-flex justify-items-center mt-100 mb-100">
          <h5 className="col-12 text-center">Brak wyników dla danego zapytania</h5>
        </div>
      );
    }

    const currentPage = searchLotResults?.data.pagination.current || 1;
    const totalPages = searchLotResults?.data.pagination.pages || 1;

    if (searchLotResults) {
      return (
        <>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          <SearchResults searchType={SearchType.Lot} />
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </>
      );
    }

    return useSearchForm;
  };

  return (
    <>
      <HeroArea isHomeScreen={false} searchType={SearchType.Lot} />
      <section className="category-page section search-results pt-0 fill-content" ref={resultsRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="category-grid-list">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="nav-list"
                        role="tabpanel"
                        aria-labelledby="nav-list-tab"
                      >
                        {renderSearchResults()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchLot;
