import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { emptyCity, emptyStreet, useSearchStore } from '../../../../store/searchStore';
import { City, Street, fetchCitySuggestions, fetchStreetSuggestions } from '../../../../services/suggestionApi';
import SearchInputWithSuggestion from './SearchInputWithSuggestion';
import SearchInput from './SearchInput';
import SearchButton from './SearchButton';
import styles from '../SearchForm.module.scss';

const AddressSearchForm: React.FC = () => {
  const navigate = useNavigate();

  const searchAddress = useSearchStore((state) => state.searchAddress);
  const setScrollToResults = useSearchStore((state) => state.setScrollToResults);
  const selectedCity = useSearchStore((state) => state.currentAddressSearch.city);
  const setCity = useSearchStore((state) => state.setCurrentAddressSearchCity);
  const selectedStreet = useSearchStore((state) => state.currentAddressSearch.street);
  const setSelectedStreet = useSearchStore((state) => state.setCurrentAddressSearchStreet);
  const selectedStreetNumber = useSearchStore((state) => state.currentAddressSearch.streetNumber);
  const setStreetNumber = useSearchStore((state) => state.setCurrentAddressSearchStreetNumber);
  const selectedFlatNumber = useSearchStore((state) => state.currentAddressSearch.flatNumber);
  const setFlatNumber = useSearchStore((state) => state.setCurrentAddressSearchFlatNumber);
  const [initialCityValue, setInitialCityValue] = useState<City | undefined>(undefined);
  const [initialStreetValue, setInitialStreetValue] = useState<Street | undefined>(undefined);
  const setClearSuggestionInput = useSearchStore((state) => state.setClearSuggestionInput);

  const isCitySelected = selectedCity.id !== 0;

  useEffect(() => {
    if (selectedCity) {
      setInitialCityValue(selectedCity);
    }
    if (selectedStreet) {
      setInitialStreetValue(selectedStreet);
    }
  }, [selectedCity, selectedStreet]);

  const fetchStreetSuggestionsWithSelectedCity = async (query: string) => {
    return selectedCity ? await fetchStreetSuggestions(selectedCity.id, query) : [];
  };

  const handleSearch = () => {
    if (selectedCity) {
      const params = {
        city: selectedCity,
        street: selectedStreet || undefined,
        streetNumber: selectedStreetNumber || '',
        flatNumber: selectedFlatNumber || '',
        page: 1,
      };
      setScrollToResults(true);
      searchAddress(params);
      navigate('/ksiegi-wieczyste-po-adresie');
    }
  };

  const onCityInputChange = () => {
    setCity(emptyCity);
    setClearSuggestionInput(true);
    setSelectedStreet(emptyStreet);
    setStreetNumber('');
    setFlatNumber('');
  };

  const onStreetInputChange = () => {
    setSelectedStreet(emptyStreet);
  };

  return (
    <div className={`row ${styles.searchInputs}`}>
      <div className="col-12">
        <SearchInputWithSuggestion<City>
          placeholder="Miejscowość"
          onSelect={setCity}
          fetchSuggestions={fetchCitySuggestions}
          fillInput={selectedCity?.city}
          onInputChange={onCityInputChange}
          initialValue={initialCityValue}
        />
      </div>
      <div className="col-12">
        <SearchInputWithSuggestion<Street>
          placeholder="Ulica"
          onSelect={setSelectedStreet}
          fetchSuggestions={fetchStreetSuggestionsWithSelectedCity}
          disabled={!isCitySelected}
          fillInput={selectedStreet?.street}
          initialValue={initialStreetValue}
          onInputChange={onStreetInputChange}
          clearable={true}
        />
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Numer budynku"
          disabled={!isCitySelected}
          value={selectedStreetNumber}
          onChange={(e) => setStreetNumber(e.target.value)}
        />
      </div>
      <div className="col-12">
        <SearchInput
          placeholder="Numer lokalu"
          disabled={!isCitySelected}
          value={selectedFlatNumber}
          onChange={(e) => setFlatNumber(e.target.value)}
        />
      </div>
      <div className="col-12">
        <Tippy content="Wybierz miejscowość" disabled={!!isCitySelected}>
          <SearchButton isDisabled={!isCitySelected} onClick={handleSearch} />
        </Tippy>
      </div>
    </div>
  );
};

export default AddressSearchForm;
